import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    invoiceDetails: [],
    isFetching: false,
    error: false,
    errorMessage: '',
    noInternet: '',
    modalState: false,
    salesOfferDetails: [],
    taxValue: {}
};

export const quoteInvoiceSlice = createSlice({
    name: 'quoteInvoice',
    initialState,
    reducers: {
        getInvoiceRequest: (state, action) => {
            state.isFetching = action.payload;
            state.error = false;
            state.errorMessage = '';
            state.noInternet = '';
        },
        getInvoiceSuccess: (state, action) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.noInternet = '';
            state.invoiceDetails = action.payload;
        },
        getInvoiceFailure: (state) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = '';
            state.noInternet = '';
        },
        getSalesOfferDetails: (state, action) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.noInternet = '';
            state.salesOfferDetails = action.payload;
        },
        getTaxValue: (state, action) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.noInternet = '';
            state.taxValue = action.payload;
        },
    }
},
)

export const { getInvoiceRequest, getInvoiceSuccess, getInvoiceFailure, getSalesOfferDetails,getTaxValue } = quoteInvoiceSlice.actions;
export default quoteInvoiceSlice.reducer;
