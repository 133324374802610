import qs from "qs";

/**

* Get full Strapi URL from path

* @param {string} path Path of the URL

* @returns {string} Full Strapi URL

*/

export function getStrapiURL(path = "") {
    return `${process.env.REACT_APP_PUBLIC_STRAPI_API_URL || "https://dev.api2.solargolfnet.com"}${path}`;

}


export function getStrapiMediaURL(path: any) {
    return `${process.env.REACT_APP_PUBLIC_STRAPI_API_URL || "https://dev.api2.solargolfnet.com"
        }/api${path}`;
}

/**

* Helper to make GET requests to Strapi API endpoints

* @param {string} path Path of the API route

* @param {Object} urlParamsObject URL params object, will be stringified

* @param {Object} options Options passed to fetch

* @returns Parsed API call response

*/

export async function fetchAPI(path: any, urlParamsObject = {}, options = {}) {

    // Merge default and user options

    const mergedOptions = {

        headers: {

            "Content-Type": "application/json",

        },

        ...options,

    };




    // Build request URL

    const queryString = qs.stringify(urlParamsObject);

    const requestUrl = `${getStrapiURL(

        `/api${path}${queryString ? `?${queryString}` : ""}`

    )}`;




    // Trigger API call

    const response = await fetch(requestUrl, mergedOptions);

    const data = await response.json();

    return data;

}