import React from "react";


export const UserIcon = [
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 13.9341V12.6841C12.5 12.021 12.2366 11.3852 11.7678 10.9163C11.2989 10.4475 10.663 10.1841 10 10.1841H5C4.33696 10.1841 3.70107 10.4475 3.23223 10.9163C2.76339 11.3852 2.5 12.021 2.5 12.6841V13.9341"
        stroke="#C4C6D5"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 7.68408C8.88071 7.68408 10 6.56479 10 5.18408C10 3.80337 8.88071 2.68408 7.5 2.68408C6.11929 2.68408 5 3.80337 5 5.18408C5 6.56479 6.11929 7.68408 7.5 7.68408Z"
        stroke="#C4C6D5"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>,
  ];
  export const EmailStartIcon = [
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2493 7.8083C10.2493 9.267 9.06682 10.4495 7.60812 10.4495C6.14942 10.4495 4.96692 9.267 4.96692 7.8083C4.96692 6.3496 6.14942 5.1671 7.60812 5.1671C9.06682 5.1671 10.2493 6.3496 10.2493 7.8083ZM10.2493 7.8083V8.6887C10.2493 9.66116 11.0377 10.4495 12.0101 10.4495C12.6348 10.4495 13.1835 10.1242 13.4961 9.63375C13.6701 9.3607 13.7709 9.03648 13.7709 8.6887V7.8083C13.7709 4.40468 11.0117 1.64549 7.60812 1.64549C4.2045 1.64549 1.44531 4.40468 1.44531 7.8083C1.44531 11.2119 4.2045 13.9711 7.60812 13.9711C8.91749 13.9711 10.1315 13.5628 11.1297 12.8665"
        stroke="#C4C6D5"
        strokeWidth="1.3206"
      />
    </svg>,
  ];
  
  export const LockIcon = [
    <svg
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.7662 6.44057V3.79937C3.7662 2.34067 4.94871 1.15816 6.40741 1.15816C7.8661 1.15816 9.04861 2.34067 9.04861 3.79937V6.44057M2.0054 6.44057H10.8094C11.2956 6.44057 11.6898 6.83474 11.6898 7.32097V12.6034C11.6898 13.0896 11.2956 13.4838 10.8094 13.4838H2.0054C1.51917 13.4838 1.125 13.0896 1.125 12.6034V7.32097C1.125 6.83474 1.51917 6.44057 2.0054 6.44057Z"
        stroke="#C4C6D5"
        strokeWidth="1.3206"
      />
    </svg>,
  ];
  