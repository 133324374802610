import { Grid } from '@mui/material'
import React from 'react'
import Login from '../../components/authentication/login/Login'
import { ForAuthentication } from './Hooks'


const UserModule = () => {
    const {
        loginForm,
        notificationLogin,
        forgotPasswordFormik,
        handleOpenForgotModal,
        handleCloseForgotModal,
        openForgotModal,
        changeContent
    } = ForAuthentication();
    return (
        <div className='h-screen'>
            <Login
                loginForm={loginForm}
                notificationLogin={notificationLogin}
                forgotPasswordFormik={forgotPasswordFormik}
                handleOpenForgotModal={handleOpenForgotModal}
                handleCloseForgotModal={handleCloseForgotModal}
                openForgotModal={openForgotModal}
                changeContent={changeContent}
            />
        </div>
    )
}

export default UserModule