let snap = 20; //Pixels to snap

const intersectFun = (options: any, editorMemo: any) => {
    options.target.setCoords();
    var bound = options.target.getBoundingRect();
    // Don't allow objects off the canvas
    if (bound.left < snap) {
        options.target.set({ left: 0 });
    }

    if (bound.top < snap) {
        options.target.set({ top: 0 });
    }

    if ((options.target.width + bound.left) > (editorMemo.width - snap)) {
        options.target.set({ left: (editorMemo.width - options.target.width) + 0.625 });
    }

    if ((options.target.height + bound.top) > (editorMemo.height - snap)) {
        options.target.set({ top: (editorMemo.height - options.target.height) + 0.625 });
    }

    // Loop through objects
    editorMemo.forEachObject(function (obj: any) {
        if (obj === options.target) return;

        const objBound = obj.getBoundingRect();

        // If objects intersect
        if (obj.objectType === 'panel' && (options.target.isContainedWithinObject(obj) || options.target.intersectsWithObject(obj) || obj.isContainedWithinObject(options.target))) {

            var distX = ((objBound.left + obj.width) / 2) - ((bound.left + options.target.width) / 2);
            var distY = ((objBound.top + obj.height) / 2) - ((bound.top + options.target.height) / 2);

            // Set new position
            findNewPos(distX, distY, options.target, obj);
        }

        // Snap objects to each other horizontally

        // If bottom points are on same Y axis
        if (Math.abs((bound.top + options.target.height) - (objBound.top + obj.height)) < snap) {
            // Snap target BL to object BR
            if (Math.abs(bound.left - (objBound.left + obj.width)) < snap) {
                options.target.set({ left: (objBound.left + obj.width) + 0.625 });
                options.target.set({ top: (objBound.top + obj.height - options.target.height) + 0.625 });
            }

            // Snap target BR to object BL
            if (Math.abs((bound.left + options.target.width) - objBound.left) < snap) {
                options.target.set({ left: (objBound.left - options.target.width) + 0.625 });
                options.target.set({ top: (objBound.top + obj.height - options.target.height) + 0.625 });
            }
        }

        // If top points are on same Y axis
        if (Math.abs(bound.top - objBound.top) < snap) {
            // Snap target TL to object TR
            if (Math.abs(bound.left - (objBound.left + obj.width)) < snap) {
                options.target.set({ left: (objBound.left + obj.width) + 0.625 });
                options.target.set({ top: objBound.top + 0.625 });
            }

            // Snap target TR to object TL
            if (Math.abs((bound.left + options.target.width) - objBound.left) < snap) {
                options.target.set({ left: (objBound.left - options.target.width) + 0.625 });
                options.target.set({ top: objBound.top + 0.625 });
            }
        }

        // Snap objects to each other vertically

        // If right points are on same X axis
        if (Math.abs((bound.left + options.target.width) - (objBound.left + obj.width)) < snap) {
            // Snap target TR to object BR
            if (Math.abs(bound.top - (objBound.top + obj.height)) < snap) {
                options.target.set({ left: (objBound.left + obj.width - options.target.width) + 0.625 });
                options.target.set({ top: (objBound.top + obj.height) + 0.625 });
            }

            // Snap target BR to object TR
            if (Math.abs((bound.top + options.target.height) - objBound.top) < snap) {
                options.target.set({ left: (objBound.left + obj.width - options.target.width) + 0.625 });
                options.target.set({ top: (objBound.top - options.target.height) + 0.625 });
            }
        }

        // If left points are on same X axis
        if (Math.abs(bound.left - objBound.left) < snap) {
            // Snap target TL to object BL
            if (Math.abs(bound.top - (objBound.top + obj.height)) < snap) {
                options.target.set({ left: objBound.left + 0.625 });
                options.target.set({ top: (objBound.top + obj.height) + 0.625 });
            }

            // Snap target BL to object TL
            if (Math.abs((bound.top + options.target.height) - objBound.top) < snap) {
                options.target.set({ left: objBound.left + 0.625 });
                options.target.set({ top: (objBound.top - options.target.height) + 0.625 });
            }
        }
    });


    options.target.setCoords();

    // If objects still overlap

    let outerAreaLeft: any;
    let outerAreaTop: any;
    let outerAreaRight: any;
    let outerAreaBottom: any;

    editorMemo.forEachObject(function (obj: any) {
        if (obj === options.target) return;

        const objBound = obj.getBoundingRect();

        if (obj.objectType === 'panel' && (options.target.isContainedWithinObject(obj) || options.target.intersectsWithObject(obj) || obj.isContainedWithinObject(options.target))) {

            var intersectLeft = null,
                intersectTop = null,
                intersectWidth: any = null,
                intersectHeight: any = null,
                intersectSize = null,
                targetLeft = bound.left,
                targetRight = targetLeft + options.target.width,
                targetTop = options.target.top,
                targetBottom = targetTop + options.target.height,
                objectLeft = objBound.left,
                objectRight = objectLeft + obj.width,
                objectTop = objBound.top,
                objectBottom = objectTop + obj.height;

            // Find intersect information for X axis
            if (targetLeft >= objectLeft && targetLeft <= objectRight) {
                intersectLeft = targetLeft;
                intersectWidth = obj.width - (intersectLeft - objectLeft);

            } else if (objectLeft >= targetLeft && objectLeft <= targetRight) {
                intersectLeft = objectLeft;
                intersectWidth = options.target.width - (intersectLeft - targetLeft);
            }

            // Find intersect information for Y axis
            if (targetTop >= objectTop && targetTop <= objectBottom) {
                intersectTop = targetTop;
                intersectHeight = obj.height - (intersectTop - objectTop);

            } else if (objectTop >= targetTop && objectTop <= targetBottom) {
                intersectTop = objectTop;
                intersectHeight = options.target.height - (intersectTop - targetTop);
            }

            // Find intersect size (this will be 0 if objects are touching but not overlapping)
            if (intersectWidth > 0 && intersectHeight > 0) {
                intersectSize = intersectWidth * intersectHeight;
            }

            // Set outer snapping area
            if (objBound.left < outerAreaLeft || outerAreaLeft == null) {
                outerAreaLeft = objBound.left;
            }

            if (objBound.top < outerAreaTop || outerAreaTop == null) {
                outerAreaTop = objBound.top;
            }

            if ((objBound.left + obj.width) > outerAreaRight || outerAreaRight == null) {
                outerAreaRight = objBound.left + obj.width;
            }

            if ((objBound.top + obj.height) > outerAreaBottom || outerAreaBottom == null) {
                outerAreaBottom = objBound.top + obj.height;
            }

            // If objects are intersecting, reposition outside all shapes which touch
            if (intersectSize) {
                var distX = (outerAreaRight / 2) - ((bound.left + options.target.width) / 2);
                var distY = (outerAreaBottom / 2) - ((options.target.top + options.target.height) / 2);

                // Set new position
                findNewPos(distX, distY, options.target, obj);
            }
        }
    });
}

function findNewPos(distX: any, distY: any, target: any, obj: any) {
    // See whether to focus on X or Y axis
    const objBound = obj.getBoundingRect();
    if (Math.abs(distX) > Math.abs(distY)) {
        if (distX > 0) {
            target.set({ left: (objBound.left - target.width) + 0.625 });
        } else {
            target.set({ left: (objBound.left + obj.width) + 0.625 });
        }
    } else {
        if (distY > 0) {
            target.set({ top: (objBound.top - target.width) + 0.625 });
        } else {
            target.set({ top: (objBound.top + obj.height) + 0.625 });
        }
    }
}
export default intersectFun;