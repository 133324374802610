/**
 * @uplnupurS
 * This component taken from flo
 */
import React, { useState } from "react";
import { TextField, InputAdornment } from "@mui/material";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import Icon from "@mui/material/Icon";
import { LockIcon } from "../../../common/svg_files";
import styles from "./PasswordField.module.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const PasswordFieldInput = (props: any) => {
    const [showPassword, setShowPassword] = useState(false);
    // const [] = useState(true);
    const lockSvg = React.useState(LockIcon);

    const clickEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            // Do code here
            e.preventDefault();
            e.stopPropagation();
            props.clickEnter();
        }
    };

    const onHandleChange = (event: any) => {
        console.log(event, 'event $$$');
        if(props.onChange){
            props.onChange(event);
        }
    };

    return (
        <div className={`textfield  ${props.extracls}`}>
            <label className={`${props.lableCls}`}>{props.label}</label>
            <TextField
                style={props.style}
                autoFocus={props.autoFocus}
                onChange={onHandleChange}
                disabled={props.disabled}
                id={props.id}
                name={props.passwordinputText}
                variant="outlined"
                label={props.inputLabel}
                defaultValue={props.defaultValue}
                className={`${styles.textfieldclass} ${props.textnewclass ? props.textnewclass : ""
                    }`}
                type={"password"}
                placeholder={props.placeholder}
                autoComplete="off"
                multiline={props.multiline}
                rows={props.rows}
                fullWidth={props.fullwidthState}
                error={props.error}
                value={props.value}
                inputProps={props.inputProps}
                onKeyPress={clickEnter}
                helperText={props.helperText}
                data-cy={props.dataCy}
                tabIndex={props.tabIndex}
                InputProps={{
                    className: "pr-2 !rounded-[8px] border-none",
                    type: showPassword ? "text" : "password",
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                tabIndex={-1}
                            >
                                <Icon className="text-20" color="action" tabIndex={-1}>
                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </Icon>
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    );
};

PasswordFieldInput.prototype = {
    extralabelcls: PropTypes.string,
    textnewclass: PropTypes.string,
    textinputname: PropTypes.string,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.string,
    error: PropTypes.bool,
    fullwidthState: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    inputProps: PropTypes.object,
    required: PropTypes.bool,
    value: PropTypes.string,
    clickEnter: PropTypes.func,
    startAdorment: PropTypes.node,
    helperText: PropTypes.string,
    tabIndex: PropTypes.number,
};

export default PasswordFieldInput;
