import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import ButtonFieldInput from '../buttonField/Button'
import magicImg from '../../../Images/header/magicLink.jpg'
import getLocalStorageData from '../../../utils/getLocalStorageData'
import crossicon from "../../../Images/Icons/cross.svg"

const ConfirmMagicLinkModal = (props: any) => {
    const userMail  = getLocalStorageData('email');
    return (
        <div>
            <Dialog open={props.open} onClose={props.onClose} className={`ConfirmMagicLinkModal`}>
                <DialogTitle className={`!pt-8 relative`}>
                    <div className={`flex items-center justify-center h-2`}>
                        <p className={`text-xl text-black font-medium`}>Check your email</p>
                        {props?.isClose &&
                        <div className={`cursor-pointer absolute right-5 top-7`} onClick={props.handlecloseCheckMailModal}>
                            <img
                                src={crossicon}
                                alt='crossicon'
                                height={15}
                                width={15}
                            />
                        </div>}
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className={`flex flex-col justify-start items-center gap-6`}>
                        <img src={magicImg} alt="img" height={200} width={200}/>
                        <p className={`text-sm text-gn-black-500 w-80 text-center`}>We have sent you a magic link to <span className={`text-sm text-[#4a91ed]`}>{userMail ? userMail : 'unknowuser123@gmail.com'}</span> for a password-free sign in.</p>
                        <p className={`text-sm text-gn-black-500 w-80 text-center`}>Please open the email on the <span className={`text-base text-black`}>SAME DEVICE</span> you are trying to login with.</p>
                        {props?.isClose && <p className={`text-sm text-gn-black-500 w-80 text-center cursor-pointer`} onClick={props?.resendEmail} >Didn't get your email?<span className={`text-sm text-[#4a91ed]`}>Resend Email</span></p>}
                        <div>
                            <p className={`text-sm text-gn-black-500 text-center`}>Still having issues?</p>
                            <p className={`text-sm text-gn-black-500 text-center`}>Contact support@solarnets.com</p>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    {!props.isClose &&
                    <div className={`!pb-6 !px-4`}>
                        <ButtonFieldInput
                            name="Continue"
                            type="submit"
                            handleButton={() => props.netSupportRedirect()}
                            textextracls={`capitalize`}
                            buttonextracls={`!bg-[#2F80ED] !hover:bg-[#2F80ED] !text-white text-base  !py-2 !px-6 !rounded-3xl font-normal font-[SansRegular] justify-end`}
                            loading={props.magicFormBtnLoader}
                        />
                    </div>}
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ConfirmMagicLinkModal