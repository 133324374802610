// import * as types from "../../../redux/actiontypes"
import { createSlice } from '@reduxjs/toolkit';


export const initialState = {
    userDetails: {},
    addressDetails:{},
    isFetching: false,
    error: false,
    errorMessage: '',
    noInternet: '',
};

export const userSlice = createSlice({
    name: 'userAuthentication',
    initialState,
    reducers: {
        loginUserRequest: (state, action) => {
            state.isFetching = action.payload;
            state.error = false;
            state.errorMessage = '';
            state.noInternet = '';
        },
        loginUserSuccess: (state, action) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.noInternet = '';
            state.userDetails = action.payload;
        },
        loginUserFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.payload;
        },
        saveUserDetails: (state, action) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.noInternet = '';
            state.userDetails = action.payload;
        },
        saveAddressDetails: (state, action) => { 
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.noInternet = '';
            state.addressDetails = action.payload;
        }
    }
},
)

export const { loginUserRequest, loginUserSuccess, loginUserFailure, saveUserDetails, saveAddressDetails } = userSlice.actions;
export default userSlice.reducer;