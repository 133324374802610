import { Tooltip } from "@mui/material";
import roundtick from "../../../Images/Icons/roundtick.svg"

const Panel = (props: any) => {
  const { item, setSelectedPanels, selectedPanels } = props;
  let isSelected = selectedPanels?.find((id: any) => item._id == id);

  const handleSelectedPanels = () => {
    if (isSelected) {
      let newSelectedPanels = selectedPanels?.filter((id: any) => item._id !== id);
      setSelectedPanels(newSelectedPanels);
    } else {
      setSelectedPanels((prev: any) => [...prev, item._id]);
    }
  };
  return (
    <div
      onClick={handleSelectedPanels}
      className="w-[100%] flex justify-center items-center"
    >
      <div
        className={`${
          isSelected && "bg-gray-300"
        } relative w-full h-[10rem] flex flex-col items-center justify-center gap-4 p-5 md:hover:bg-gray-100 transition-all text-center cursor-pointer rounded-lg border mb-4`}
      >
        {isSelected && <img src={roundtick} className="absolute top-2 right-2 w-4" alt="img"/>}
        <p
          className={`${parseInt(item?.heightMm) > parseInt(item?.widthMm)
              ? `h-[5rem] w-[3rem]`
              : `h-[3rem] w-[5rem]`
            }`}
          style={{
            background: `${item?.color ? item?.color : "#013A7D"}`,
          }}
        ></p>
        {/* Following this format to print => "Default 1(5'5”x3'4”)" */}
        <div className="flex flex-col items-center justify-center">
          <Tooltip title={item?.name} arrow disableInteractive placement={`right`}>
            <p className="text-[12px] truncate w-[6rem]">{item?.name}</p>
          </Tooltip>
          <Tooltip arrow disableInteractive placement={`right`} title={`${item?.heightMm} mm x ${item?.widthMm} mm`}>
            <p className="text-[11px] truncate w-[6rem]">{item?.heightMm} mm x {item?.widthMm} mm</p>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default Panel;
