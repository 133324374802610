import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import crossicon from "../../../Images/Icons/cross.svg";
import Panel from "./Panel";
import PropTypes from "prop-types";
import ButtonFieldInput from "../buttonField/Button";
import TextFieldInput from "../FormFields/TextFieldInput";

import text from "../../en_US.json";

const SearchPanelModal = (props: any) => {
  const closeModal = () => {
    props.handlePanleLibLoginMsg()
    props.onClose()
    props.handleDefaultPanelSearch("")
  }
  return (
    <div>
      <Dialog open={props.open} onClose={closeModal} className={`panelSearchModal`}>
        <DialogTitle className={`!py-5 border-b border-slate-200 w-full`}>
          {/* <> */}
            {/* {props.panleLibLoginMsg &&
              <div className="flex justify-center items-center absolute top-0 w-full mx-[-23px] border-t-[2.5px] border-[#047AFF]">
                <div className="bg-[#047AFF] min-w-fit min-h-fit rounded-bl rounded-br gap-4 px-6 py-1 z-50 absolute top-0 right-[13.5rem]">
                  <p className="text-white text-sm text-center">Please login to add panel</p>
                </div>
              </div>} */}
            <div className={`flex items-center justify-between h-2`}>
              <p className={`text-xl text-black font-medium`}>{props.title}</p>
              <div className={`cursor-pointer`} onClick={closeModal}>
                <img src={crossicon} alt="crossicon" height={15} width={15} />
              </div>
            </div>
          {/* </> */}
        </DialogTitle>
        <DialogContent
          className="flex justify-center items-start flex-col w-full"
        >
          <div className="py-4 w-full flex justify-between gap-2 items-center">
            <div className="w-3/4">
              <TextFieldInput
                fullWidth={true}
                style={{
                  boxShadow: "inset 0px 4px 8px rgba(0, 0, 0, 0.05)",
                  width: "100%",

                }}
                textnewclass={`roundInput w-full outline-none rounded-full border border-gray-400 sm:px-2 sm:py-1 max-sm:text-[12px]`}
                placeholder={`Search Panel`}
                type={`text`}
                textinputname={`Search_Panel`}
                id={`Search_Panel`}
                onChange={(e:any) => props.handleDefaultPanelSearch(e.target.value)}
                value={props.defaultPanelSearchValue}
                autoFocus={true}
              />
            </div>
            <div className="w-max">
              <ButtonFieldInput
                name={props?.selectedPanels?.length > 0 ? `${props?.selectedPanels?.length} selected` : 'Select Panel'}
                type="button"
                handleButton={()=>  props.addSelectDefaultPanels(props?.selectedPanels)}
                textextracls={`capitalize`}
                buttonextracls={`${props?.selectedPanels?.length > 0 ? "!bg-[#2F80ED] !hover:bg-[#2F80ED] !text-white":"!bg-gray-400" } sm:text-base text-[10px]  sm:!py-2 sm:!px-4 py-1 px-2 !rounded-3xl font-normal font-[SansRegular]`}
                disabled={props?.selectedPanels?.length === 0}
                loading={props.loader}

              />
            </div>
          </div>
          <div className={`h-[450px] w-[100%] overflow-y-scroll ${props.defaultPanels.length > 0 ? "searchResult_Panel items-start" : "flex justify-center items-center"}`}>
            { props.defaultPanels.length > 0 ? props.defaultPanels.filter((item: any) => !isNaN(item?.heightMm) || !isNaN(item?.widthMm)).map((item: any, idx: number) => {
              return <Panel
                key={idx}
                item={item}
                setSelectedPanels={props.setSelectedPanels}
                selectedPanels={props.selectedPanels}
              /> 
            }) : <h1 className="text-xl font-bold">{text.searchedPanelNotFound}</h1>} 
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

SearchPanelModal.propTypes = {
  defaultPanels: PropTypes.array,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  handleDefaultPanelSearch: PropTypes.func,
  defaultPanelSearchValue: PropTypes.string,
  defaultPanelItems: PropTypes.array,
  addSelectDefaultPanels: PropTypes.func,
  loader: PropTypes.bool,
  selectedPanels: PropTypes.array,
  setSelectedPanels: PropTypes.func,
  panleLibLoginMsg: PropTypes.bool,
  handlePanleLibLoginMsg: PropTypes.func
};

export default SearchPanelModal;



