import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import ButtonFieldInput from '../../common/uiComponents/buttonField/Button'
import crossicon from '../../Images/Icons/cross.svg'

const GotoLoginModal = (props: any) => {
    return (
        <div>
            <Dialog open={props.open} onClose={props.onClose}>
                <DialogTitle className={`p-0`}>
                    <div className={`flex justify-end h-2`}>
                        <div className={`cursor-pointer`} onClick={props.onClose}>
                            <img
                                src={crossicon}
                                alt='crossicon'
                                height={15}
                                width={15}
                            />
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className={`w-80 flex items-center justify-between flex-col`}>
                        <div className={`mb-4`}>
                            {/* <p className={`text-xl text-center font-bold font-[SansRegular] mb-2`}>{`You haven't logged into your account.`}</p> */}
                            <p className={`text-[#333333] text-center text-xl font-medium font-[SansRegular]`}>{`To continue your payment, please log in.`}</p>
                        </div>
                        <div>
                            <ButtonFieldInput
                                name="Go to Login"
                                type="submit"
                                // handleButton={() => { props.createPayment(invoiceList?.total) }}
                                handleButton={() => { props.gotoLogin() }}
                                buttonextracls={`!bg-[#2F80ED] !hover:bg-[#2F80ED] !text-white text-base  !py-2 !px-6 !rounded-3xl font-normal font-[SansRegular]`}
                            />
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default GotoLoginModal