import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import ButtonFieldInput from "../buttonField/Button";
import crossicon from "../../../Images/Icons/cross.svg";
import TextFieldInput from "../FormFields/TextFieldInput";
import getLocalStorageData from "../../../utils/getLocalStorageData";

const MagicLinkModal = (props: any) => {
  return (
    <div>
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle className={`!py-5 border-b border-slate-200`}>
          <div className={`flex items-center justify-between h-2`}>
            <p className={`text-xl text-black font-medium`}>{props?.title}</p>
            <div className={`cursor-pointer`} onClick={props.onClose}>
              <img src={crossicon} alt="crossicon" height={15} width={15} />
            </div>
          </div>
        </DialogTitle>
        <form onSubmit={props.magicLinkForm.handleSubmit}>
          <DialogContent>
            <div className="w-full flex flex-col gap-4">
              <p className={`w-[23rem] text-xs text-black font-light`}>
                {props?.description}
              </p>
              <TextFieldInput
                fullWidth={true}
                style={{
                  boxShadow: "inset 0px 4px 8px rgba(0, 0, 0, 0.05)",
                  width: "23rem",
                }}
                textnewclass={`bg-gn-white-10 rounded-[8px] w-[100%]`}
                placeholder={`Enter Email`}
                type={`text`}
                textinputname={`email`}
                id={`email`}
                onChange={props.magicLinkForm.handleChange}
                onBlur={props.magicLinkForm.handleBlur}
                value={props.magicLinkForm.values.email}
                error={
                  props.magicLinkForm.touched.email &&
                  Boolean(props.magicLinkForm.errors.email)
                }
                errText={
                  props.magicLinkForm.touched.email &&
                  props.magicLinkForm.errors.email
                }
                clickEnter={props.magicLinkForm.handleSubmit}
                disabled={getLocalStorageData("email") ? true : false}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <div className={`flex justify-between gap-3 py-4`}>
              {props?.isCancelBtn && (
                <div className={`magicCancelBtn`}>
                  <ButtonFieldInput
                    type={`reset`}
                    variant={"outline"}
                    name={`Cancel`}
                    handleButton={
                      props?.isCancelBtn
                        ? props.handleCancelMagiModal
                        : props.onClose
                    }
                    buttonextracls={`!text-black text-base !px-6 flex items-center !font-normal !font-[SansRegular] cursor-pointer`}
                  />
                </div>
              )}
              <div className={`!pb-2 !px-4`}>
                <ButtonFieldInput
                  name="Generate Magic Link"
                  type="submit"
                  // handleButton={() => { }}
                  textextracls={`capitalize`}
                  buttonextracls={`!bg-[#2F80ED] !hover:bg-[#2F80ED] !text-white text-base  !py-2 !px-4 !rounded-3xl font-normal font-[SansRegular]`}
                  loading={props.magicFormBtnLoader}
                />
              </div>
            </div>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default MagicLinkModal;
