import React from "react"
import store from "./redux/configureStore";
import { Provider as ReduxProvier } from "react-redux"
import { I18nextProvider } from "react-i18next"
import i18next from "i18next"
import "./translations"

const Provider = ({ children }: { children: React.ReactNode }) => {
  return (
    <ReduxProvier store={store}>
      <I18nextProvider i18n={i18next}>{children}</I18nextProvider>
    </ReduxProvier>
  )
}

export default Provider
