/* eslint-disable react-hooks/exhaustive-deps */
// import img from 'next/image'
import React, { useEffect } from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { useSelector } from 'react-redux'
import ButtonFieldInput from '../../common/uiComponents/buttonField/Button'
import TextFieldInput from '../../common/uiComponents/FormFields/TextFieldInput'
import addressIcon from '../../Images/admin/address.svg'
import emailIcon from '../../Images/admin/email.svg'
import phoneIcon from '../../Images/admin/phone.svg'
import r from "../../images/addUser/Rarraow.svg"
import EmailFieldInput from '../../common/uiComponents/commonEmailField/EmailField'
import PhoneFieldInput from '../../common/uiComponents/FormFields/PhoneFieldInput'
import styles from './AddShipping.module.css'
import Checkbox from '@mui/material/Checkbox';
import getLocalStorageData from '../../utils/getLocalStorageData'
import backLogo from "../../Images/addUser/blackLeft.svg"
import nextLogo from "../../Images/addUser/Rarraow.svg"
import editIcon from '../../Images/Icons/whiteEdit.svg'
import PlacesAutoCompleteCustom from '../../common/uiComponents/placesAutoComplete/PlacesAutoCompleteCustom'
import { useSearchParams, useNavigate } from 'react-router-dom';
import BillingAddress from '../../common/uiComponents/billingAddress/BillingAddress'
import SelectInputField from '../../common/uiComponents/FormFields/SelectInputField'
import country from '../../common/country.json';
import Text from '../../common/en_US.json';
import { getLabel } from '../../utils/commonFunc'


const AddShippingAddr = (props: any) => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    let userDetails = getLocalStorageData("user")
    const YOUR_GOOGLE_MAPS_API_KEY = `${process.env.NEXT_PUBLIC_YOUR_GOOGLE_MAPS_API_KEY}`;


    useEffect(() => {
        // props.getUserDetails()
        // props.getInvoiceDetails()
        // props.getSupports()
    }, [])

    const handleBack = () => {
        navigate(`/quote/netsupport?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}&index=${searchParams.get('index') ?? 0}`)

    }
    // Route to net page to add another page
    const backToEditor = () => {
        let userId = getLocalStorageData("userId") ? getLocalStorageData("userId") : getLocalStorageData("user")?._id ? getLocalStorageData("user")?._id : searchParams.get('userId');
        navigate(`/quote/editor?userId=${userId}&quoteId=${searchParams.get('quoteId')}&index=${searchParams.get('index') ?? 0}`)
    }

    const countryOption = country.map((state) => {
        return { label: state.name, value: state.code };
    });

    const checkAddress = (address: string, str: string) => {
        // Convert the input string to lowercase for case-insensitive matching
        const lowercaseStr = address.toLowerCase();

        // Create regular expressions for the city and state, making sure they are case-insensitive
        const addressRegex = new RegExp(str.toLowerCase(), 'i');

        // Test if both "state" and "city" are present
        const isPresent = addressRegex.test(lowercaseStr);

        // Return true if present, otherwise return false
        return isPresent;
    }

    return (
        <div className={`w-full`}>
            <div className={`w-full flex justify-around mt-6`}>
                <div>
                    <p className={`text-[#333333] text-xl mb-1 font-roboto font-medium`}>Billing Address</p>
                    {/* <p className={`text-[#828282] text-sm mb-4 font-normal`}>Can you change also billing address</p> */}
                    {userDetails?.address ?
                        <div className={` border-2 border-[#F2F2F2] rounded-[10px] w-[30rem] p-4 ${styles.billingCard}`}>
                            <div className={`flex items-center text-[#4F4F4F] text-base`}><p>{userDetails?.name}</p></div>
                            <div className={`flex items-center text-[#828282] text-sm`}><div className={`w-[35px] h-[35px] flex justify-center items-center`}><img alt='address' src={addressIcon} height={15} width={15} /></div> <p>
                                {userDetails?.address?.street},{checkAddress(userDetails?.address?.street, userDetails?.address?.city) ? '' : userDetails?.address?.city + ','}  {checkAddress(userDetails?.address?.street, userDetails?.address?.state) ? '' : userDetails?.address?.state + ','} {userDetails?.address?.zipcode + ','} {getLabel(userDetails?.country, country)}
                            </p></div>
                            <div className={`flex items-center text-[#828282] text-sm`}><div className={`w-[35px] h-[35px] flex justify-center items-center`}><img alt='phone' src={phoneIcon} height={15} width={15} /></div><p>{userDetails?.phoneNo}</p></div>
                            <div className={`flex items-center text-[#828282] text-sm`}><div className={`w-[35px] h-[35px] flex justify-center items-center`}><img alt='mail' src={emailIcon} height={15} width={15} /></div><p>{userDetails?.email}</p></div>
                        </div>
                        :
                        <BillingAddress billingAddFormik={props.billingAddFormik}
                            billingAddressVal={props.billingAddressVal} billingAddress={props.billingAddress} billingAdLoader={props.billingAdLoader}
                        />}
                </div>
                {userDetails && userDetails?.hasOwnProperty('address') &&
                    <div className={`w-[40%]`}>
                        <div>
                            <p className={`text-[#333333] text-xl font-roboto font-medium mb-4`}>Add Shipping Address</p>
                        </div>
                        <div className={`flex items-center`}>
                            <Checkbox
                                disabled={userDetails?.address?.street ? false : true}
                                checked={props.showWillCall}
                                onChange={(e: any) => { props.handleWillCall(e) }}
                            />
                            <p>Will call</p>
                        </div>
                        {props.showWillCall === true ?
                            <div className={`w-[100%] border-2 border-[#F2F2F2] rounded-[10px] p-4 ${styles.billingCard}`}>
                                <div className={` p-4 `}>
                                    {/* <div className={`flex items-center text-[#4F4F4F] text-base`}><p>{userDetails?.name}</p></div> */}
                                    <div className={`flex items-center text-[#828282] text-sm`}><div className={`w-[35px] h-[35px] flex justify-center items-center`}><img alt='address' src={addressIcon} height={20} width={20} /></div> <p>The SunnyCal Solar Store 795 S. Hwy 49, Jackson, CA 95642, United States</p></div>
                                    <div className={`flex items-center text-[#828282] text-sm`}><div className={`w-[35px] h-[35px] flex justify-center items-center`}><img alt='phone' src={phoneIcon} height={20} width={20} /></div><p>209-464-6100</p></div>
                                    <div className={`flex items-center text-[#828282] text-sm`}><div className={`w-[35px] h-[35px] flex justify-center items-center`}><img alt='mail' src={emailIcon} height={20} width={20} /></div><p>sales@solargolfnet.com</p></div>
                                </div>
                                <div className={`w-[100%] py-4 flex justify-between`}>
                                    <ButtonFieldInput
                                        name="Back"
                                        type="reset"
                                        handleButton={handleBack}
                                        // endIcon={<img src={r} width={18} height={18} alt="arrow" />}
                                        buttonextracls={`!text-black text-base !bg-[#D5D5D5] !hover:bg-[#FFF] !py-2 !px-6 !rounded-3xl font-normal font-[SansRegular] cursor-pointer`}
                                        startIcon={<img src={backLogo} width={22} height={22} alt="arrow" />}
                                    />
                                    <ButtonFieldInput
                                        name="SAVE"
                                        type="submit"
                                        endIcon={<img src={nextLogo} width={18} height={18} alt="arrow" />}
                                        handleButton={() => props.addSupports(Number(searchParams.get('index')) ?? 0, props.willCallAddress)}
                                        // endIcon={<img src={r} width={18} height={18} alt="arrow" />}
                                        buttonextracls={`!text-white text-base !bg-[#2F80ED] !hover:bg-[#2F80ED] !py-2 !px-6 !rounded-3xl font-normal font-[SansRegular] cursor-pointer`}
                                    />
                                </div>
                            </div>

                            :
                            <>
                                <div className={`flex items-center`}>
                                    <Checkbox
                                        disabled={userDetails?.address?.street ? false : true}
                                        checked={props.showBillingAsShipping}
                                        onChange={(e: any) => { props.handleShippingAddressChange(e) }}
                                    />
                                    <p>Same as billing address</p>
                                </div>
                                <form onSubmit={props.addShippingFormik.handleSubmit} className={` border-2 border-[#F2F2F2] rounded-[10px] p-4 ${styles.billingCard}`}>
                                    <div className={`flex flex-col gap-5`}>
                                        <p className="text-sm font-medium text-[#2F80ED]">Add New Address</p>

                                        <TextFieldInput
                                            style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
                                            textnewclass={`bg-gn-white-10 rounded-[8px] addaddressInputFieldsGlobal`}
                                            placeholder={`Full Name`}
                                            type={`text`}
                                            textinputname={`name`}
                                            id={`name`}
                                            // extracls={`w-[16rem] sm:w-[30rem]`}
                                            onChange={props.addShippingFormik.handleChange}
                                            onBlur={props.addShippingFormik.handleBlur}
                                            value={props.addShippingFormik.values.name}
                                            error={
                                                props.addShippingFormik.touched.name &&
                                                Boolean(props.addShippingFormik.errors.name)
                                            }
                                            errText={
                                                props.addShippingFormik.touched.name &&
                                                props.addShippingFormik.errors.name
                                            }
                                            clickEnter={props.addShippingFormik.handleSubmit}
                                            disabled={userDetails?.address?.street ? false : true}
                                        />
                                        <div className={`flex mb-4`}>
                                            <EmailFieldInput
                                                style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
                                                textnewclass={`bg-gn-white-10 rounded-[8px] addUserInputFieldsGlobal`}
                                                placeholder={`Email id`}
                                                type={`email`}
                                                textinputname={`email`}
                                                id={`email`}
                                                // extracls={`w-[16rem] sm:w-[30rem]`}
                                                onChange={props.addShippingFormik.handleChange}
                                                onBlur={props.addShippingFormik.handleBlur}
                                                value={props.addShippingFormik.values.email}
                                                error={
                                                    props.addShippingFormik.touched.email &&
                                                    Boolean(props.addShippingFormik.errors.email)
                                                }
                                                errText={
                                                    props.addShippingFormik.touched.email &&
                                                    props.addShippingFormik.errors.email
                                                }
                                                clickEnter={props.addShippingFormik.handleSubmit}
                                                disabled={userDetails?.address?.street ? false : true}
                                            />

                                            <PhoneFieldInput
                                                style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
                                                textnewclass={`bg-gn-white-10 !border !border-solid !border-[#E0E0E0] !rounded-lg !w-[15em] !ml-4 !h-[42px]`}
                                                // textnewclass={``}
                                                placeholder={`Phone Number`}
                                                textinputname={`phoneno`}
                                                // id={`phoneno`}
                                                onChange={props.addShippingFormik.handleChange}
                                                // onBlur={props.addShippingFormik.handleBlur}
                                                value={props.addShippingFormik.values.phoneno}
                                                error={
                                                    props.addShippingFormik.touched.phoneno &&
                                                    Boolean(props.addShippingFormik.errors.phoneno)
                                                }
                                                errorText={
                                                    props.addShippingFormik.touched.phoneno &&
                                                    props.addShippingFormik.errors.phoneno
                                                }
                                                // dataCy={'editProfile-input-phone'}
                                                disabled={userDetails?.address?.street ? false : true}
                                            />
                                        </div>

                                    </div>
                                    <div className={`flex flex-col gap-5`}>
                                        <div>
                                            <div className='autoField'>
                                                <div onClick={props.clearAddress}>
                                                    {/* <GooglePlacesAutocomplete
                                            apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                                            selectProps={{
                                                isClearable: true,
                                                value: !props.shippingAddr?.label ? null : props.shippingAddr,
                                                onChange: (val: any) => {
                                                    props.shippingAddressVal(val);
                                                },
                                                placeholder: 'Address'

                                            }}
                                        /> */}

                                                    <PlacesAutoCompleteCustom
                                                        id={`address`}
                                                        shippingAddressVal={props.shippingAddressVal}
                                                        getAddressObject={props.getAddressObject}
                                                        shippingAddr={props.shippingAddr}
                                                        disabled={userDetails?.address?.street ? false : true}
                                                    />
                                                </div>
                                            </div>
                                            <p className={`text-red-500 text-xs`}>{props.addShippingFormik.touched.address &&
                                                props.addShippingFormik.errors.address}</p>
                                        </div>
                                        <div className={`flex flex-col md:flex-row gap-5 w-[16rem] sm:w-[31rem]`}>
                                            <TextFieldInput
                                                style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
                                                textnewclass={`bg-gn-white-10 rounded-[8px] addaddressInputFieldsGlobal`}
                                                placeholder={`City`}
                                                textinputname={`city`}
                                                id={`city`}
                                                type={`text`}
                                                onChange={props.addShippingFormik.handleChange}
                                                onBlur={props.addShippingFormik.handleBlur}
                                                value={props.addShippingFormik.values.city}
                                                error={
                                                    props.addShippingFormik.touched.city &&
                                                    Boolean(props.addShippingFormik.errors.city)
                                                }
                                                errText={
                                                    props.addShippingFormik.touched.city &&
                                                    props.addShippingFormik.errors.city
                                                }
                                                clickEnter={props.addShippingFormik.handleSubmit}
                                                disabled={userDetails?.address?.street ? false : true}
                                            />
                                            <TextFieldInput
                                                style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
                                                textnewclass={`bg-gn-white-10 rounded-[8px] addaddressInputFieldsGlobal`}
                                                placeholder={`State`}
                                                textinputname={`state`}
                                                id={`state`}
                                                type={`text`}
                                                onChange={props.addShippingFormik.handleChange}
                                                onBlur={props.addShippingFormik.handleBlur}
                                                value={props.addShippingFormik.values.state}
                                                error={
                                                    props.addShippingFormik.touched.state &&
                                                    Boolean(props.addShippingFormik.errors.state)
                                                }
                                                errText={
                                                    props.addShippingFormik.touched.state &&
                                                    props.addShippingFormik.errors.state
                                                }
                                                clickEnter={props.addShippingFormik.handleSubmit}
                                                disabled={userDetails?.address?.street ? false : true}
                                            />
                                        </div>
                                        <div className={`flex flex-col md:flex-row gap-5 w-[16rem] sm:w-[31rem]`}>
                                            <TextFieldInput
                                                style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
                                                textnewclass={`bg-gn-white-10 rounded-[8px] addaddressInputFieldsGlobal`}
                                                placeholder={`Zip Code`}
                                                textinputname={`zipcode`}
                                                id={`zipcode`}
                                                type={`text`}
                                                onChange={props.addShippingFormik.handleChange}
                                                onBlur={props.addShippingFormik.handleBlur}
                                                value={props.addShippingFormik.values.zipcode}
                                                error={
                                                    props.addShippingFormik.touched.zipcode &&
                                                    Boolean(props.addShippingFormik.errors.zipcode)
                                                }
                                                errText={
                                                    props.addShippingFormik.touched.zipcode &&
                                                    props.addShippingFormik.errors.zipcode
                                                }
                                                clickEnter={props.addShippingFormik.handleSubmit}
                                                disabled={userDetails?.address?.street ? false : true}
                                            />
                                            <SelectInputField
                                                inputLabel={Text.CountrySelectTxt}
                                                placeholder={Text.CountrySelectTxt}
                                                selectoptions={countryOption}
                                                textinputname='country'
                                                onChange={props.addShippingFormik.handleChange}
                                                displayEmpty={true}
                                                value={props.addShippingFormik?.values.country}
                                                error={
                                                    props.addShippingFormik?.touched.country &&
                                                    Boolean(props.addShippingFormik?.errors.country)
                                                }
                                                helperText={
                                                    props.addShippingFormik?.touched.country &&
                                                    props.addShippingFormik?.errors.country
                                                }
                                                clickEnter={props.addShippingFormik?.handleSubmit}
                                            />
                                        </div>
                                    </div>
                                    <div className={`flex w-full justify-between`}>
                                        <div className={`my-4 mr-4 border border-[#E0E0E0] rounded-3xl`}>
                                            <ButtonFieldInput
                                                name="Back"
                                                type="reset"
                                                handleButton={handleBack}
                                                // endIcon={<img src={r} width={18} height={18} alt="arrow" />}
                                                buttonextracls={`!text-black text-base !bg-[#FFF] !hover:bg-[#FFF] !py-2 !px-6 !rounded-3xl font-normal font-[SansRegular] cursor-pointer`}
                                                startIcon={<img src={backLogo} width={22} height={22} alt="arrow" />}
                                            />
                                        </div>
                                        <div className={`my-4 mr-4 border border-[#E0E0E0] rounded-3xl`}>
                                            <ButtonFieldInput
                                                name="Edit Net"
                                                type="reset"
                                                handleButton={backToEditor}
                                                endIcon={<img src={editIcon} width={18} height={18} alt="arrow" />}
                                                buttonextracls={`!text-white text-base !bg-[#2F80ED] !hover:bg-[#2F80ED] !py-2 !px-6 !rounded-3xl font-normal font-[SansRegular] cursor-pointer`}
                                            />
                                        </div>
                                        <div className={`py-4`}>
                                            <ButtonFieldInput
                                                name="SAVE"
                                                type="submit"
                                                endIcon={<img src={nextLogo} width={18} height={18} alt="arrow" />}
                                                // handleButton={}
                                                // endIcon={<img src={r} width={18} height={18} alt="arrow" />}
                                                buttonextracls={`!text-white text-base !bg-[#2F80ED] !hover:bg-[#2F80ED] !py-2 !px-6 !rounded-3xl font-normal font-[SansRegular] cursor-pointer`}
                                                loading={props.addressLoading}
                                                disabled={props.addressLoading === true ? true : userDetails?.address?.street ? false : true}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </>
                        }

                    </div>}
            </div>
        </div>
    )
}

export default AddShippingAddr