import { createSlice } from '@reduxjs/toolkit';


export const initialState = {
    getUserDiagrams: [],
    selectedDiagramData: {},
    isFetching: false,
    error: false,
    errorMessage: '',
    noInternet: '',
    quoteData: {},
    allDiagramShapes: [],
    selectedShape: {},
    getAllSupportList:[]
};

export const quoteDiagramSlice = createSlice({
    name: 'quoteDiagram',
    initialState,
    reducers: {
        getDiagramRequest: (state, action) => {
            state.isFetching = action.payload;
            state.error = false;
            state.errorMessage = '';
            state.noInternet = '';
        },
        getDiagramSuccess: (state, action) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.noInternet = '';
            state.getUserDiagrams = action.payload;
        },
        getQuoteData: (state, action) => {
            state.quoteData = action.payload;
        },
        saveAllSupportList: (state, action) => {
            state.getAllSupportList = action.payload;
        }
    }
},
)

export const { getDiagramRequest, getDiagramSuccess, getQuoteData, saveAllSupportList } = quoteDiagramSlice.actions;
export default quoteDiagramSlice.reducer;