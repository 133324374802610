import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import logger from 'redux-logger'
import diagramReducer from '../container/quote/quoteReducer/diagramReducer'
import userReducer from '../container/authentication/userReducer/userReducer'
import NotificationReducer from '../container/notificationReducer/NotificationReducer'
import InvoiceReducer from '../container/quote/invoiceReducer/invoiceReducer'
import helpModalReducer from './helpModalSlice'

const store = configureStore({
    reducer: {
     diagramReducer: diagramReducer,
    userReducer: userReducer,
    notificationReducer: NotificationReducer,
    invoiceReducer: InvoiceReducer,
    helpModalReducer: helpModalReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  })
  
  export type AppDispatch = typeof store.dispatch
  export const useAppDispatch: () => AppDispatch = useDispatch // Export a hook that can be reused to resolve types
  
  export default store