import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import ButtonFieldInput from '../../common/uiComponents/buttonField/Button';
import TextFieldInput from '../../common/uiComponents/FormFields/TextFieldInput'
import next from '../../Images/commonImg/Rarraow.svg'
// import back from '../../Images/commonImg/blackLeft.svg'
import text from '../../common/en_US.json'
import PlacesAutoCompleteCustom from "../../common/uiComponents/placesAutoComplete/PlacesAutoCompleteCustom";

const index = (props: any) => {

  // const YOUR_GOOGLE_MAPS_API_KEY = `${process.env.REACT_APP_PUBLIC_YOUR_GOOGLE_MAPS_API_KEY}`;

  return (
    <div className={`flex flex-col justify-center items-center pt-16 gap-10`}>
      <div className={`flex flex-col justify-center items-center`}>
        <p className={`text-2xl text-[#333333] tracking-wide font-medium font-[SansRegular]`}>{text.address}</p>
        {/* <p className={`text-base text-[#828282] tracking-wide text-center px-4 md:px-0 font-normal font-[SansRegular]`}>{text.pick}</p> */}
      </div>
      <form onSubmit={props.addressFormik.handleSubmit}>
        <div className={`flex flex-col gap-8`}>
          <div>
            <div className='autoField'>
              {/* <Autocomplete
              id="address"
              style={{ width: "100%", height: "5.5vh", padding: "0.7rem", border: "1px solid #E0E0E0", borderRadius: "0.3rem" }}
              // value={props.addressFormik.values.address}
              apiKey={YOUR_GOOGLE_MAPS_API_KEY}
              onPlaceSelected={(place) => {
                props.addressFormik.setFieldValue("address", place.formatted_address);
              }}
              onChange={props.addressFormik.handleChange}
            /> */}
              {/* <GooglePlacesAutocomplete
                apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                selectProps={{
                  isClearable: true,
                  value: props.address,
                  onChange: (val: any) => {
                    props.changeAddress(val);
                  },
                  placeholder: 'Address'

                }}
              /> */}

              <PlacesAutoCompleteCustom
                id={`address`}
                getAddressObject={props.getAddressObject}
                shippingAddressVal={props.shippingAddressVal}
                shippingAddr={props.shippingAddr}
                changeAddress={props.changeAddress}
                forComponent='addAddress'
              />

            </div>
            <p className={`text-red-500 text-xs`}>{props.addressFormik.touched.address &&
              props.addressFormik.errors.address}</p>
          </div>
          <div className={`flex flex-col md:flex-row gap-6 w-[16rem] sm:w-[30rem]`}>
            <TextFieldInput
              style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
              textnewclass={`bg-gn-white-10 rounded-[8px] addaddressInputFieldsGlobal`}
              placeholder={`City`}
              textinputname={`city`}
              id={`city`}
              type={`text`}
              onChange={props.addressFormik.handleChange}
              onBlur={props.addressFormik.handleBlur}
              value={props.addressFormik.values.city}
              error={
                props.addressFormik.touched.city &&
                Boolean(props.addressFormik.errors.city)
              }
              errText={
                props.addressFormik.touched.city &&
                props.addressFormik.errors.city
              }
              clickEnter={props.addressFormik.handleSubmit}
            />
            <TextFieldInput
              style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
              textnewclass={`bg-gn-white-10 rounded-[8px] addaddressInputFieldsGlobal`}
              placeholder={`State`}
              textinputname={`state`}
              id={`state`}
              type={`text`}
              onChange={props.addressFormik.handleChange}
              onBlur={props.addressFormik.handleBlur}
              value={props.addressFormik.values.state}
              error={
                props.addressFormik.touched.state &&
                Boolean(props.addressFormik.errors.state)
              }
              errText={
                props.addressFormik.touched.state &&
                props.addressFormik.errors.state
              }
              clickEnter={props.addressFormik.handleSubmit}
            />
            <TextFieldInput
              style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
              textnewclass={`bg-gn-white-10 rounded-[8px] addaddressInputFieldsGlobal`}
              placeholder={`Zip Code`}
              textinputname={`zipcode`}
              id={`zipcode`}
              type={`text`}
              onChange={props.addressFormik.handleChange}
              onBlur={props.addressFormik.handleBlur}
              value={props.addressFormik.values.zipcode}
              error={
                props.addressFormik.touched.zipcode &&
                Boolean(props.addressFormik.errors.zipcode)
              }
              errText={
                props.addressFormik.touched.zipcode &&
                props.addressFormik.errors.zipcode
              }
              clickEnter={props.addressFormik.handleSubmit}
            />
          </div>
        </div>
        <div className={`flex justify-end w-[16rem] sm:w-[30rem] pt-24`}>
          {/* <div className={`border border-[#E0E0E0] rounded-3xl`}>
            <ButtonFieldInput
              type={`submit`}
              name={`Back`}
              startIcon={<Image src={bL} width={22} height={22} alt="arrow" />}
              // handleButton={}
              buttonextracls={`!text-black text-base !py-2 !px-6 !font-normal !font-[SansRegular] cursor-pointer`}
            />
          </div> */}
          <ButtonFieldInput
            name="Next"
            type="submit"
            // handleButton={}
            endIcon={<img src={next} width={18} height={18} alt="arrow" />}
            buttonextracls={`!text-white text-base !bg-[#1473E6] !hover:bg-[#1473E6] !py-2 !px-6 !rounded-3xl font-normal font-[SansRegular] cursor-pointer`}
          />
        </div>
      </form>
      {/* <p>After submitting NEXT, check your inbox for your login credentials</p> */}
    </div>
  )
}

export default index