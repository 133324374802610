import React, { useEffect } from 'react'
import styles from "./Index.module.css"
import alReadyDone from '../../../Images/Icons/alReadyDone.svg'
import { useLocation } from 'react-router';

const QuoteHeader = (props: any) => {
  const location = useLocation();
  return (
    <div className={`flex justify-center items-center pt-8`}>
      <div className={`flex items-center`}>

        {/* #1 */}
        <div className={` ${styles.outer} flex items-center mr-4 gap-2`}>
          <img src={alReadyDone} alt="alReadyDone" height={22} width={22} />
          <p className={`text-[#1f1f1f] font-normal text-base font-roboto`}>Email Id</p>
        </div>
        <span className={`w-10 border border-solid border-[#828282]`}></span>

        {/* #2 */}
        <div className={(location.pathname === '/quote/addaddress' || location.pathname === '/quote/addquote' || location.pathname === '/quote/netsupport' || location.pathname === '/quote/invoice' || location.pathname === '/quote/payment') ? `${styles.outer} flex items-center mx-4 gap-2` : 'flex items-center mx-4 gap-2'}>
          {location.pathname === '/quote/addaddress' || location.pathname === '/quote/addquote' || location.pathname === '/quote/netsupport' || location.pathname === '/quote/invoice' || location.pathname === '/quote/payment' ? <img src={alReadyDone} alt="alReadyDone" height={22} width={22} /> : <p className={`flex justify-center items-center text-[#4F4F4F] font-normal border border-[#A9A9A9] w-[22px] h-[22px] rounded-full font-[SansRegular] text-sm`}>
          </p>}
          <p className={`text-[#1f1f1f] font-normal text-base font-roboto`}>Address</p>
        </div>
        <span className={`w-10 border border-solid border-[#828282]`}></span>

        {/* #3 */}
        <div className={(location.pathname === '/quote/addquote' || location.pathname === '/quote/netsupport' || location.pathname === '/quote/invoice' || location.pathname === '/quote/payment') ? `${styles.outer} flex items-center mx-4 gap-2` : 'flex items-center mx-4 gap-2'}>
          {location.pathname === '/quote/addquote' || location.pathname === '/quote/netsupport' || location.pathname === '/quote/invoice' || location.pathname === '/quote/payment' ? <img src={alReadyDone} alt="alReadyDone" height={22} width={22} /> : <p className={`flex justify-center items-center text-[#4F4F4F] font-normal border border-[#A9A9A9] w-[22px] h-[22px] rounded-full font-[SansRegular] text-sm`}>
          </p>}
          <p className={`text-[#1f1f1f] font-normal text-base font-roboto`}>Create Shape</p>
        </div>
        <span className={`w-10 border border-solid border-[#828282]`}></span>

        {/* #4 */}
        <div className={(location.pathname === '/quote/netsupport' || location.pathname === '/quote/invoice' || location.pathname === '/quote/payment') ? `${styles.outer} flex items-center mx-4 gap-2` : 'flex items-center mx-4 gap-2'}>
          {location.pathname === '/quote/netsupport' || location.pathname === '/quote/invoice' || location.pathname === '/quote/payment' ? <img src={alReadyDone} alt="alReadyDone" height={22} width={22} /> : <p className={`flex justify-center items-center text-[#4F4F4F] font-normal border border-[#A9A9A9] w-[22px] h-[22px] rounded-full font-[SansRegular] text-sm`}>
          </p>}
          <p className={`text-[#1f1f1f] font-normal text-base font-roboto`}>Add Supports</p>
        </div>
        <span className={`w-10 border border-solid border-[#828282]`}></span>

        {/* #5 */}
        <div className={(location.pathname === '/quote/invoice' || location.pathname === '/quote/payment') ? `${styles.outer} flex items-center mx-4 gap-2` : 'flex items-center mx-4 gap-2'}>
          {location.pathname === '/quote/invoice' || location.pathname === '/quote/payment' ? <img src={alReadyDone} alt="alReadyDone" height={22} width={22} /> : <p className={`flex justify-center items-center text-[#4F4F4F] font-normal border border-[#A9A9A9] w-[22px] h-[22px] rounded-full font-[SansRegular] text-sm`}>
          </p>}
          <p className={`text-[#1f1f1f] font-normal text-base font-roboto`}>Quote</p>
        </div>
        <span className={`w-10 border border-solid border-[#828282]`}></span>

        {/* #6 */}
        <div className={(location.pathname === '/quote/payment') ? `${styles.outer} flex items-center ml-4 gap-2` : 'flex items-center ml-4 gap-2'}>
          {location.pathname === '/quote/payment' ? <img src={alReadyDone} alt="alReadyDone" height={22} width={22} /> : <p className={`flex justify-center items-center text-[#4F4F4F] font-normal border border-[#A9A9A9] w-[22px] h-[22px] rounded-full font-[SansRegular] text-sm`}>
          </p>}
          <p className={`text-[#1f1f1f] font-normal text-base font-roboto`}>Payment</p>
        </div>

      </div>
    </div>
  )
}

export default QuoteHeader