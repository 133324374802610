import { TextField, InputAdornment } from "@mui/material";
import {UserIcon} from '../../svg_files';
import PropTypes from "prop-types";
import { ErrorOutlined } from "@mui/icons-material";
import styles from "./Index.module.css";

function TextFieldInput(props: any) {
	/**
	 * clickEnter for enter button
	 * @param e
	 */
	const clickEnter = (e: any) => {
		if (e.key === "Enter") {
			// Do code here
			e.preventDefault();
			props.clickEnter();
		}
	};
	const onChange = (event: any) => {
		if (props.onChange) {
			let value = event.target.value;
			let newValue = value.replace(/^\s+/g, "");
			event.target.value = newValue;
			props.onChange(event);
		}
	};

	return (
		<div className={`relative flex flex-col ${props.extracls} textfield`}>
			<label className={`font-normal text-[14px]  ${props.lableCls}`}>
				{props.label}
			</label>
			<TextField
				style={props.style}
				autoFocus={props.autoFocus}
				onChange={(e) => onChange(e)}
				disabled={props.disabled}
				id={props.id}
				variant={props.variant ? props.variant : "outlined"}
				label={props.inputLabel}
				defaultValue={props.defaultValue}
				className={`${styles.textfieldclass} ${props.textnewclass ? props.textnewclass : ""
					}`}
				type={props.type}
				autoComplete={props.autoComplete || "off"}
				name={props.textinputname}
				multiline={props.multiline}
				maxRows={props.maxRows}
				minRows={props.minRows}
				fullWidth={props.fullwidthState}
				placeholder={props.placeholder}
				error={props.error}
				value={props.value}
				inputProps={props.inputProps}
				helperText={props.helperText}
				onKeyPress={(e) => clickEnter(e)}
				required={props.required}
				onBlur={props.onBlur}
				InputProps={{
					endAdornment: props.email ? (
						<InputAdornment position="end">
							<ErrorOutlined className="erroricon" />
						</InputAdornment>
					) : props.endIcon,
					startAdornment: props.startAdorment && (
						<InputAdornment position="start">
							{UserIcon}
						</InputAdornment>
					),
					disableUnderline: props.disableUnderline,
				}}
			/>
			<p className={`text-red-500 text-[12px] pt-0.5 pl-1.5 ${props.errTextExtraRoot}`}>
				{props.errText}
			</p>
		</div>
	);
}
TextFieldInput.prototype = {
	inputLabel: PropTypes.string,
	extralabelcls: PropTypes.string,
	textnewclass: PropTypes.string,
	textinputname: PropTypes.string,
	disabled: PropTypes.bool,
	defaultValue: PropTypes.string,
	error: PropTypes.bool,
	fullwidthState: PropTypes.bool,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	inputProps: PropTypes.object,
	required: PropTypes.bool,
	value: PropTypes.string,
	clickEnter: PropTypes.func,
	startAdorment: PropTypes.node,
	helperText: PropTypes.string,
	startIcon: PropTypes.string,
	label: PropTypes.string,
	type: PropTypes.string,
	dataCy: PropTypes.string,
	errText: PropTypes.string,
	disableUnderline: PropTypes.string,
};

export default TextFieldInput;