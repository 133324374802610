import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import styles from "./Index.module.css";


const SelectInputField = (props: any): JSX.Element => {


  const handleChange = (event: any) => {
    props.onChange(event);

  };

  return (
    <FormControl fullWidth>

      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        variant='outlined'
        defaultValue={props.defaultValue}
        onChange={(e) => handleChange(e)}
        // label={props.inputLabel}
        // displayEmpty={props.displayEmpty}
        name={props.textinputname}
        value={props.value ? props.value : props.placeholder}
        autoWidth={true}
        placeholder="Select country"
        className={(props.selecterror ? 'errorinput' : '') + ' selectinput ' + props.selectnewclass}
        style={{ height: '42px', width: '216px' }}
        error={props?.error && props?.error}
      >
        {props.placeholder &&
          <MenuItem value='Select country' disabled >
            {"Select country"}
          </MenuItem>
        }
        {props.selectoptions.map((item: any, index: number) => {
          return (
            <MenuItem key={index} value={item.value} disabled={item.disabled}>
              {item.label}
            </MenuItem>
          );
        }
        )
        }
      </Select>
      <FormHelperText style={{ color: "red" }}>
        {props.helperText}
      </FormHelperText>
    </FormControl>
  )
}

export default SelectInputField