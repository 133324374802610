import React from "react";
// import { TextField, InputAdornment } from "@mui/material";
import PropTypes from "prop-types";
import { EmailStartIcon } from "../../svg_files";
// import { EmailStartIcon } from "../../common/svg_files";
import styles from "./Index.module.css";
import { TextField } from "@mui/material";

const EmailFieldInput = (props: any) => {
  const emailStartSvg = React.useState(EmailStartIcon);

  /**
   * clickEnter for enter button
   * @param e
   */
  const clickEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      // Do code here
      e.preventDefault();
      e.stopPropagation();
      props.clickEnter();
    }
  };

  const onChange = (event: any) => {
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <div className={`textfieldinput flex flex-col ${props.extracls}`}>
      <label className={`${props.lableCls}`}>{props.label}</label>
      <TextField
        style={props.style}
        autoFocus={props.autoFocus}
        onChange={onChange}
        disabled={props.disabled}
        id={props.id}
        variant="outlined"
        label={props.inputLabel}
        defaultValue={props.defaultValue}
        className={`${styles.textfieldclass} ${props.textnewclass ? props.textnewclass : ""
          }`}
        type={"email"}
        autoComplete="off"
        name={props.textinputname}
        multiline={props.multiline}
        rows={props.rows}
        fullWidth={props.fullwidthState}
        placeholder={props.placeholder}
        error={props.error}
        value={props.value}
        onKeyPress={clickEnter}
        required={props.required}
        helperText={props.helperText}
        data-cy={props.dataCy}
        InputProps={
          {
            // startAdornment: (
            //   <InputAdornment position="start">
            //     {/* {emailStartSvg} */}
            //     </InputAdornment>
            // ),
          }
        }
        tabIndex={props.tabIndex}
      />
      <p className="text-red-500 text-[12px] pt-0.5 pl-1.5">
        {props.errText}
      </p>
    </div>
  );
};

EmailFieldInput.prototype = {
  inputLabel: PropTypes.string,
  textnewclass: PropTypes.string,
  textinputname: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  error: PropTypes.bool,
  fullwidthState: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  inputProps: PropTypes.object,
  required: PropTypes.bool,
  value: PropTypes.string,
  clickEnter: PropTypes.func,
  helperText: PropTypes.string,
  dataCy: PropTypes.string,
  tabIndex: PropTypes.number,
};

export default EmailFieldInput;