
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./checkoutPage";
import { doGetApiCall, doPostApiCall } from "../../utils/ApiConfig";
import getLocalStorageData from "../../utils/getLocalStorageData";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise: any = loadStripe(`${process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY}`);

const Payment: any = (props: any) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    // const router = useRouter();
    // const [clientSecret, setClientSecret] = useState("");
    let clientSecret = getLocalStorageData("client_secret");

    // let clientSecret = 'pi_3N1nJeHCLos0VvAh1YjLyXWE_secret_l40DJomOSH489B85xD6CMUbsL';
    const invoiceList = useSelector((state: any) => state?.invoiceReducer?.invoiceDetails)
    // const [loadPage, setLoadPage] = useState(false)
    const [loadPage, setLoadPage] = useState(true)
    const myAccountList = useSelector((state: any) => state?.myAccountReducer?.myAccountDetails)
    const orderIndex = Number(getLocalStorageData("orderIndex"))

    const paymentData = orderIndex ? myAccountList[orderIndex] : invoiceList

    useEffect(() => {
        if (invoiceList) {
            setLoadPage(true)
        }
        else {
            setLoadPage(false)
        }

    }, [invoiceList])

    const appearance = {
        theme: 'stripe',
    };
    const options: any = {
        clientSecret,
        appearance,
    };
    useEffect(() => {
        props.getInvoiceDetails();
        if (searchParams.get('total')) {
            props.createPayment(searchParams.get('total'), searchParams.get('quoteId'))
        }
    }, [])


    return (
        (loadPage && clientSecret) &&
        <div className={`w-full bg-[#FAFAFA] h-screen flex items-center justify-around`}>
            <div className={`w-1/3`}>
                <p className={`text-[#333333] font-[SansRegular] text-xl`}>Choose your payment method</p>
                <div className={`p-10 border-[1px] border-[#BBBBBB] rounded-lg bg-white`}>
                    <Elements options={options} stripe={stripePromise}>
                        <CheckoutForm invoiceList={invoiceList} />
                    </Elements>
                </div>
            </div>
            <div>
                <p className={`text-[#333333] font-[SansRegular] text-xl`}>Summary</p>
                <div className={`w-[372px] px-10 pt-10 border-[1px] border-[#BBBBBB] rounded-lg bg-white`}>
                    <div className={`flex justify-between items-center h-[50px]`}>
                        <p className={`text-[#333333] font-[SansRegular]`}>Sub-Total(USD)</p>
                        <p className={`text-[#333333] font-[SansRegular]`}>${Number(invoiceList?.calPrice?.subTotal).toFixed(2)}</p>
                    </div>
                    {/* {invoiceList?.discount?.percentage ?
                        <div className={`flex justify-between items-center h-[50px]`}>
                            <p className={`text-[#333333] font-[SansRegular]`}>Discount ({invoiceList?.discount?.percentage}%)</p>
                            <p className={`text-[#333333] font-[SansRegular]`}>-${Number(invoiceList?.subTotal - invoiceList?.discount?.price).toFixed(2)}</p>
                        </div>
                        : <></>}
                    {invoiceList?.discount?.percentage ?
                        <div className={`flex justify-between items-center h-[50px]`}>
                            <p className={`text-[#333333] font-[SansRegular]`}>Sub-Total</p>
                            <p className={`text-[#333333] font-[SansRegular]`}>${Number(invoiceList?.discount?.price).toFixed(2)}</p>
                        </div>
                        : <></>} */}
                    {(!invoiceList?.willCall && invoiceList?.shippingAddress?.country === 'US') && <div className={`flex justify-between items-center h-[50px]`}>
                        <p className={`text-[#333333] font-[SansRegular]`}>Shipping & Handling</p>
                        <span className='text-right text-base w-[35%] font-normal font-[SansRegular]'>${Number(invoiceList?.calPrice?.shipping).toFixed(2)}</span>
                    </div>}
                    <div className={`flex justify-between items-center h-[50px]`}>
                        {/* {searchParams.get('offerValue') && */}
                        <p className='flex justify-between w-[26rem]  py-2'>
                            <span className='text-left text-base font-normal font-[SansRegular]'>{`Discount`}</span>
                            <span className='text-right text-base w-[35%] font-normal font-[SansRegular]'>{`- $${Number(invoiceList?.calPrice?.discount?.totalAmount).toFixed(2)}`}</span>
                        </p>
                        {/* } */}
                    </div>
                    <div className={`flex justify-between items-center h-[50px]`}>
                        <p className='flex justify-between w-[26rem]  py-2'>
                            <span className='text-left text-base font-normal font-[SansRegular]'>Tax ({Number(invoiceList?.calPrice?.taxRate * 100).toFixed(2)}%)</span>
                            <span className='text-right text-base w-[35%] font-normal font-[SansRegular]'>${Number(invoiceList?.calPrice?.tax).toFixed(2)}</span>
                        </p>
                        {/* <p className={`text-[#333333] font-[SansRegular]`}>Tax ({(invoiceList?.taxRate * 100).toFixed(2)}%)</p> */}
                        {/* <p className={`text-[#333333] font-[SansRegular]`}>${Number(invoiceList?.tax).toFixed(2)}</p> */}


                    </div>
                    <div className={`flex justify-between items-center h-[100px] border-t-[1px] border-[#BBBBBB]`}>
                        <p className={`text-[#333333] font-[SansBold]`}>Total</p>
                        <p className={`text-[#333333] font-[SansBold]`}>${Number(invoiceList?.calPrice?.total).toFixed(2)}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Payment

