import getLocalStorageData from "./getLocalStorageData"

export const isAuthorised = () => {
    const role = getLocalStorageData('logedUserRole') || ''
    if (role) {
        if (role === "admin") {
            return true
        }
        else {
            return false
        }
    }
    else {
        return false
    }
}
// export const CheckAdminRoute = () => {
//     const router = useRouter()
//     if (router.route.includes('admin')) {
//         return true
//     }
//     else {
//         return false
//     }
// }

export const getAddress = (street: string, city: string, state: string, zipCode: string, country: string) => {
    console.log(street,city,state,zipCode,country ,'getAddress ##');
    if (!city || !zipCode) {
        return '';
    }
    let address = '';

    const streetArray = street ? street.split(',') : [];

    if (streetArray.length > 0) {
        address = `${streetArray[0].trim()}, ${city}, ${state}, ${zipCode}, ${country}`;
    } else {
        address = `${city}, ${state}, ${zipCode}, ${country}`;
    }

    return address;
}

export const getLabel = (code: string, array: any) => {
    let object: any = {};
    if (array) {
        object = array.find((item: { name: string, code: string }) => item?.code === code)
    }
    return object ? object.name : '';
} 

//newly added
export  const OEmbedToIframe = (detailsdata: any) => {
 
    // Define the regular expression to match oEmbed elements
    const oembedRegex = /<oembed[^>]*>.*?<\/oembed>/gi;
 
    // Replace oEmbed elements with iframes
    const modifiedHtml = detailsdata?.replace(oembedRegex, (match: string) => {
        // In this function, 'match' represents the matched oEmbed element
 
        // Extract the URL attribute from the oEmbed element
        const urlMatch = match?.match(/url="([^"]*)"/);
 
        if (urlMatch) {
            const oembedUrl = urlMatch[1];
            return `<iframe src="${oembedUrl}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
        }
        // If the URL attribute is not found, return the original match
        return match;
    });
 
    return modifiedHtml;
}




export function replaceMp4LinksWithVideo(content:any) {
    // Check if running on the client-side (browser)
    // if (process.browser) {
      const dom = new window.DOMParser().parseFromString(content, 'text/html');
      const videoLinks = dom.querySelectorAll('a[href$=".mp4"]');
  
      videoLinks.forEach((link) => {
        const videoSrc:any = link.getAttribute('href');
  
        const videoElement = document.createElement('video');
        videoElement.setAttribute('src', videoSrc);
        videoElement.setAttribute('controls', 'controls');
        videoElement.setAttribute('autoplay', "true");
  
        // Replace the anchor tag with the video tag
        if (link.parentNode) {
            link.parentNode.replaceChild(videoElement, link);
          }
      });
  
      // Extract content from the body tag
      const modifiedContent = dom.body.innerHTML;
  
      return modifiedContent;
    // } else {
    //   // Return the original content if running on the server-side
    //   return content;
    // }
}
//   This function return true if date is expired 
export function isExpired(epochTime: any) {
    // Get the current date in milliseconds.checkExpiryDate
    const now = Date.now();
  
    // Convert the epoch time to milliseconds.
    const expiryTimeInMs = epochTime * 1000;
  
    // Check if the expiry time is in the past.
    if (expiryTimeInMs < now) {
      // The expiry date has passed.
      return true;
    } else {
      // The expiry date is in the future.
      return false;
    }
  }

