import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
// import Image from 'next/image';
import React from 'react'
import EmailField from "../../../common/uiComponents/commonEmailField/EmailField";
import crossIcon from '../../../Images/Icons/cross.svg'
import ButtonFieldInput from '../../../common/uiComponents/buttonField/Button';

const ForgotPassword = (props: any) => {
    return (
        <div>
            <Dialog open={props.open} onClose={props.onClose}>
                <DialogTitle>
                    <div className={`flex justify-between`}>
                        <p>Forgot Password</p>
                        <div onClick={props.onClose} className={`cursor-pointer`}>
                            <img
                                src={crossIcon}
                                height={15}
                                width={15}
                                alt='crossIcon'
                            />
                        </div>
                    </div>
                </DialogTitle>
                <form
                    className={`w-[400px]`}
                    onSubmit={props.forgotPasswordFormik?.handleSubmit}
                >
                    <DialogContent>
                        {props.changeContent?.state ?
                            <div>
                                <p className={`text-[#616060] text-lg font-normal sm:text-sm md:text-lg  font-[SansRegular]`}>Hi <span className={`text-[#147aff] text-lg font-normal sm:text-sm md:text-lg  font-[SansRegular]`}>{props.changeContent?.data?.name}</span>,</p>
                                <p className={`text-[#616060] text-lg font-normal sm:text-sm md:text-lg  font-[SansRegular]`}>Please,check your mail <span className={`text-[#147aff] text-lg font-normal sm:text-sm md:text-lg  font-[SansRegular]`}>{props.changeContent?.data?.email}</span> for new password.</p>
                            </div>
                            : <EmailField
                                extracls={`w-full`}
                                //   lableCls={styles.labelcs}
                                placeholder="Email"
                                textinputname="email"
                                variant="outlined"
                                id="email"
                                label="Enter Email"
                                onChange={props.forgotPasswordFormik?.handleChange}
                                onBlur={props.forgotPasswordFormik?.handleBlur}
                                value={props.forgotPasswordFormik?.values.email}
                                error={
                                    props.forgotPasswordFormik?.touched.email &&
                                    Boolean(props.forgotPasswordFormik?.errors.email)
                                }
                                helperText={
                                    props.forgotPasswordFormik?.touched.email && props.forgotPasswordFormik?.errors.email
                                }
                                clickEnter={props.forgotPasswordFormik?.handleSubmit}
                            />}
                    </DialogContent>
                    <DialogActions>
                        {!props.changeContent?.state && <ButtonFieldInput
                            // disabled={props.disabled}
                            // loading={props.disabled}
                            name='Submit'
                            type="submit"
                            buttonextracls={`!text-white text-base !bg-[#2F80ED] !hover:bg-[#2F80ED] !py-1 !px-4 !rounded-3xl font-normal font-[SansRegular] cursor-pointer`}
                        />}
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}

export default ForgotPassword