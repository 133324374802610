/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import { Alert, IconButton } from "@mui/material";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { useDispatch, useSelector } from "react-redux";
import success from '../../../Images/notification/SuccessSnackbarIcon.svg';
import error from '../../../Images/notification/WarningSnackbarIcon.svg';
import warning from '../../../Images/notification/WarningSnackbarIcon.svg';
import SnackClose from '../../../Images/notification/Flo_snack_close.zip.svg';
import { setNotificationClose } from "../../../container/notificationReducer/NotificationReducer";

export interface State extends SnackbarOrigin {
  open: boolean;
}

export default function SnackBarComponent(props: any) {
  const dispatch = useDispatch()
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;

  const handleClick = (newState: SnackbarOrigin) => () => {
    setState({ open: true, ...newState });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const buttons = (
    <Button
      onClick={handleClick({
        vertical: "top",
        horizontal: "center",
      })}
    >
      Top-Center
    </Button>
  );

  const notification = useSelector((state: any) => state.notificationReducer)

  // This useEffect check if notification is open then it will close the pop up after 5 second
  React.useEffect(() => {
    if (notification?.open) {
      setTimeout(() => {
        dispatch(setNotificationClose({}));
      }, 3000);
    }
  }, [notification])

  return (
    <div>
      {/* {buttons} */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={notification?.open}
        onClick={() => dispatch(setNotificationClose({}))} //This will close modal on clicking anywhere in the snackbar
        style={{ width: "100%", zIndex: 100 }}
        key={vertical + horizontal}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={props.messageClose}
            data-cy="notifiedMessage-module-closeIcon"
          >
            <DriveFolderUploadIcon />
          </IconButton>
        }
      >

        <Alert
          onClose={props.messageClose}
          variant="filled"
          severity={notification?.alertType}
          className={`backdrop-blur-[3.5px] !rounded-lg`}
          style={{
            marginTop: '60px',
            position: 'relative',
            height: 100,
            display: "flex",
            justifyContent: 'flex-start',
            width: 400,
            alignItems: 'center',
            textAlign: "left",
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            background:
              notification?.alertType === "success"
                ? "#393a3acc"
                : notification?.alertType === "error"
                  ? "#393a3acc"
                  : notification?.alertType === "warning"
                  ? "#393a3acc"
                  : "",
          }}
          icon={
            notification?.alertType === "success" ? (
              <img src={success} alt={'SnackBarSuccess'} height={50} width={50} />
            ) : notification?.alertType === "error" ? (
              <img src={error} alt={'SnackBarError'} height={50} width={50} />
            ) :notification?.alertType === "warning" ? (
              <img src={warning} alt={'SnackBarError'} height={50} width={50} />
            ) : (
              false
            )
          }
        >
          {notification?.alertType === "success" ?
            (<div className={`bg-flo-green-50 h-flo-4.375 rounded-l w-1.5 absolute top-0 left-0 rounded-1`}></div>)
            : notification?.alertType === "error" ?
              (<div className={`bg-flo-red-50 h-flo-4.375 rounded-l w-1.5 absolute top-0 left-0 rounded-1`}></div>)
             : notification?.alertType === "warning" ?
              (<div className={`bg-flo-red-50 h-flo-4.375 rounded-l w-1.5 absolute top-0 left-0 rounded-1`}></div>)
              :
              null}

          {/* <div className={`flex w-[100%] justify-between`}>
            {notification?.alertType === "success" ? (
              <p className={`text-wv-green`}>{notification?.message}</p>
            ) : notification?.alertType === "error" ? (
              <p className={`text-wv-red`}>{notification?.message}</p>
            )  : null} */}
          {/* <CloseIcon onClick={handleClose} className={`text-slate-300 cursor-pointer text-xl`} /> */}
          {/* </div> */}

          <div className={`w-72 flex justify-between`}>
            <div>
              {notification?.alertType === "success" ? (
                <p className={`text-[#FFFFFF] text-[16px] font-normal `}>{notification?.subText}</p>
              ) : notification?.alertType === "error" ? (
                <p className={`text-[#FFFFFF] text-[16px] font-normal`}>{notification?.subText}</p>
              ) : notification?.alertType === "warning" ? (
                <p className={`text-[#FFFFFF] text-[16px] font-normal`}>{notification?.subText}</p>
              ) : null}
            </div>

            <div className={`cursor-pointer`}>
              {notification?.alertType === "success" ? (
                <img src={SnackClose} alt={'SnackClose'} height={20} width={20} />
              ) : notification?.alertType === "error" ? (
                <img src={SnackClose} alt={'SnackClose'} height={20} width={20} />
              ) : notification?.alertType === "warning" ? (
                <img src={SnackClose} alt={'SnackClose'} height={20} width={20} />
              ) : (
                false
              )
              }
            </div>
          </div>

        </Alert>
      </Snackbar>
    </div>
  );
}
