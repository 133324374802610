import { useEffect } from 'react';
import { doPutApiCall } from './../../utils/ApiConfig';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from "yup";
import { doPostApiCall } from '../../utils/ApiConfig';
import text from "../../common/en_US.json"
import getLocalStorageData from '../../utils/getLocalStorageData';
import {
    useNavigate,
    useLocation,
    useSearchParams,
} from "react-router-dom";
import {loginUserFailure, loginUserSuccess} from './userReducer/userReducer';
import { setNotificationClose, setNotificationOpen } from '../notificationReducer/NotificationReducer';
// import { setNotificationClose, setNotificationOpen } from '../notificationReducer/notificationReducer';

export const ForAuthentication = () => {

    const [loader, setLoader] = useState(false)
    const [notificationLogin, setNotificationLogin] = useState({
        open: false,
        message: "",
        subText: "",
        alertType: "",
        borderClass: "",
    });
    // const router = useRouter();

    const dispatch = useDispatch();
    const [openResetFormModal, setOpenResetFormModal] = useState(false);
    const [resetLoader, setResetLoader] = useState(false);
    const [openForgotModal, setOpenForgotModal] = useState(false);
    const [changeContent, setChangeContent] = useState({ state: false, data: {} });

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    

    // to close notification 
    const messageClose = () => {
        dispatch(setNotificationClose);
    };

    //for notification timer
    const timer = () => {
        setTimeout(messageClose, 10000);
    };



    //for login form error
    const openAuthMessage = (alertType: string, message: string, subText: string, borderClass: string) => {
        if (alertType) {
            setNotificationLogin({ open: true, message: message, subText: subText, alertType: alertType, borderClass: borderClass });
            // dispatch({ type: "AUTHENTIOCATION_ERROR", payload: notificationObj });
        }
    };
    // this function is used for logIn formik form validation
    const loginForm = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .email("Invalid email address")
                .required("Email field cannot be empty"),
            password: Yup.string()
                .min(8, "Must be 8 or more than 8 characters")
                .required("Password field is Required")
                .matches(/\w/, "Please enter valid password"),
        }),
        onSubmit: (values) => {
            logIn(values);
        },
    });
    //validation ends here

    // useEffect(() => {
    //     if (router.isReady) {
    //         loginForm.setFieldValue("email", router.query.email)
    //     }
    // }, [router])

    const logIn = (item: any) => {
        // console.log('login #');
        setLoader(true)
        dispatch({ type: "LOGIN_USER_REQUEST" });
        let data = {
            url: `${process.env.REACT_APP_PUBLIC_apiurl}/users/login`,
            bodyData: {
                email: item.email,
                password: item.password,
            },
        };
        doPostApiCall(data)
            .then((res: any) => {
                if (res?.code === "LOGIN_SUCCESS") {
                    console.log(res.token, 'login #',searchParams.get('quoteId'));
                    localStorage.setItem("user", JSON.stringify(res.result));
                    if (res?.result?.quoteId?.length > 0 && searchParams.get('format') === 'payment') {
                        navigate(`/quote/payment?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}&total=${searchParams.get('total')}&format=payment&payment=true&index=${searchParams.get('index') ?? 0}`);
                    }
                     else if (res?.result?.quoteId?.length > 0) {
                        navigate(`/quote/editor?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}`)
                    }else {
                        navigate(`/`)
                        // navigate(`/quote/payment?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}&total=${searchParams.get('total')}&payment=true`)
                    }

                    // dispatch(loginUserSuccess(res?.result));
                    // if (res?.result?.role === "admin") {
                    //     // navigate("/admin/users")                    localStorage.setItem("token", res.token);

                    // }
                    // else if (getLocalStorageData('redirect') === true) {
                    //     // navigate('/myaccount')
                    // }
                    // else if (searchParams.get('quoteId') && (searchParams.get('userId') === res.result?._id)) {
                    //     navigate(`/quote/payment?quoteId=${searchParams.get('quoteId')}&total=${searchParams.get('total')}`)
                    //     // navigate(`/quote/addshippingaddr?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}&index=${searchParams.get('index')}?? 0`);

                    // }
                    // else if (res?.result?.address) {
                    //     if (res?.result?.quoteId != null) {
                    //         // navigate(`/main/quote/addquote?quoteId=${res.result?.quoteId}&index=0`);
                    //     }
                    //     else {
                    //         let data = {
                    //             url: `${process.env.REACT_APP_PUBLIC_apiurl}/quotes`,
                    //             bodyData: {
                    //                 userId: res.result?._id,
                    //             }
                    //         }
                    //         doPostApiCall(data)
                    //             .then((res: any) => {
                    //                 if (!res.error) {
                    //                     // navigate(`/main/quote/addquote?quoteId=${res.result?._id}&index=0`);
                    //                 }
                    //             })
                    //     }
                    // }
                    // else {
                    //     // navigate(`/main/quote/addaddress`)
                    // }

                    setLoader(false)
                }else if (res.code === "NO_USER_EXISTS") {
                    openAuthMessage(
                        "error",
                        res.message,
                        text.NoUserExists,
                        "error"
                    );
                    // dispatch({ type: "LOGIN_USER_FAILURE" });
                    // dispatch({ type: 'NOTIFICATION_OPEN', payload: { message: "No User Exists", subText: `${text.NoUserExists}`, alertType: "warning", borderClass: "error" } })
                    dispatch(loginUserFailure(res?.message))
                }
                else if (res.code === "ACCOUNT_NOT_VERIFY") {
                    openAuthMessage(
                        "warning",
                        "This Account is not verified yet",
                        text.AccountNotVerify,
                        "warning"
                    );
                    dispatch(loginUserFailure(res?.message))
                }
                else if (res.code === "INCORRECT_PASSWORD") {
                    openAuthMessage(
                        "warning",
                        "Incorrect Password",
                        res.message,
                        "warning",
                    );
                    dispatch(loginUserFailure(res?.message))

                }
                else if (res.code === "USER_DISABLED") {
                    openAuthMessage(
                        "warning",
                        "Account Deactivated! Please contact admin",
                        text.UserDisabled,
                        "warning"
                    );
                    // dispatch({ type: "LOGIN_USER_FAILURE" });
                    dispatch(loginUserFailure(res?.message))

                }
                else {
                    console.log("error");
                    // dispatch({ type: "LOGIN_USER_FAILURE" });
                    dispatch(loginUserFailure(res?.message))

                }
            })
            .catch((err) => {
                console.log(err, "error");
                // dispatch({ type: "LOGIN_USER_FAILURE" });
                dispatch(loginUserFailure(err))

            });
    };

    /**
     * @uplNiladri
     * @body {
    "oldPassword": "string",
    "newPassword": "string"
      }
    @endPoint - /api/users/change/password
     */
    const changePassword = (values: any) => {
        setResetLoader(true)
        let data = {
            url: `${process.env.REACT_APP_PUBLIC_apiurl}/users/change/password`,
            bodyData: {
                oldPassword: values?.oldPassword,
                newPassword: values?.confirmPassword,
            }
        }
        doPutApiCall(data)
            .then((res: any) => {
                if (!res.error) {
                    resetPasswordFromik.resetForm()
                    setResetLoader(false)
                    setOpenResetFormModal(false);
                    dispatch({ type: 'NOTIFICATION_OPEN', payload: { message: "Passwords changed successfully", subText: `${res.message}`, alertType: "success", borderClass: "success" } })
                }
                else if (res?.code === 'TOKEN_EXPIRED') {
                    setResetLoader(false)
                    // navigate('/login')
                    dispatch({ type: 'NOTIFICATION_OPEN', payload: { message: "Unable to change password", subText: `${res.message}`, alertType: "error", borderClass: "error" } })
                }
                else {
                    setResetLoader(false)
                    dispatch({ type: 'NOTIFICATION_OPEN', payload: { message: "Unable to change password", subText: `${res.message}`, alertType: "error", borderClass: "error" } })
                }
            })
    }

    // Reset password Formik

    const resetPasswordFromik = useFormik({
        initialValues: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            oldPassword: Yup.string()
                .min(8, "Must be 8 or more than 8 characters")
                .required("Old Password field is Required")
                .matches(
                    /\w/,
                    "Please enter valid password"
                ),
            newPassword: Yup.string()
                .min(8, "Must be 8 or more than 8 characters")
                .required("Password field is Required")
                .matches(
                    /\w/,
                    "Please enter valid password"
                ),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref("newPassword"), ''], "Passwords must match")
                .required("Password field is required")
                .matches(
                    /\w/,
                    "Please enter valid password"
                ),
        }),
        onSubmit: (values: any) => {
            changePassword(values)
        },
    });

    const handleOpenResetModal = () => {
        setOpenResetFormModal(true);
    }
    const handleCloseResetModal = () => {
        resetPasswordFromik.resetForm()
        setOpenResetFormModal(false);
    }

    /** FORGOT PASSWORD API */
    const forgotPassword = (values: any) => {
        let data = {
            url: `${process.env.REACT_APP_PUBLIC_apiurl}/users/forget/password`,
            bodyData: {
                email: values.email
            }
        }
        doPutApiCall(data)
            .then((res: any) => {
                if (!res.error) {
                    // dispatch({ type: 'NOTIFICATION_OPEN', payload: { message: `Password changed, please check you mail`, subText: `Password changed, please check you mail`, alertType: "success", borderClass: "success" } })
                    // setOpenForgotModal(false)
                    dispatch(setNotificationOpen({message: `Password changed, please check your mail`, subText: `Password changed, please check your mail`}))
                    setChangeContent({ state: true, data: res.result })
                }
                else {
                    // dispatch({ type: 'NOTIFICATION_OPEN', payload: { message: `${res.message}`, subText: `${res.message}`, alertType: "error", borderClass: "error" } })
                    dispatch(setNotificationOpen({message: `${res.message}`, subText: `${res.message}`}))
                }
            })
    }

    const forgotPasswordFormik = useFormik({
        initialValues: {
            email: ""
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .email("Invalid email address")
                .required("Email field cannot be empty"),
        }),
        onSubmit: (values: any) => {
            forgotPassword(values)
        },
    })

    const handleOpenForgotModal = () => {
        setOpenForgotModal(true)
    }
    const handleCloseForgotModal = () => {
        setOpenForgotModal(false)
        setChangeContent({ state: false, data: {} })
    }

    return {
        loginForm,
        notificationLogin,
        resetPasswordFromik,
        handleOpenResetModal,
        openResetFormModal,
        handleCloseResetModal,
        resetLoader,
        forgotPasswordFormik,
        handleOpenForgotModal,
        handleCloseForgotModal,
        openForgotModal,
        changeContent
    }


}
