// import * as types from '../../redux/actiontypes';
import { createSlice } from '@reduxjs/toolkit';


export const initialState = {
    open: false,
    message: "",
    subText: "",
    alertType: "",
    borderClass: "",

};

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setNotificationOpen: (state, action) => {
            state.open = true;
            state.message = action.payload.message;
            state.subText = action.payload.subText;
            state.alertType = action.payload.alertType;
            state.borderClass = action.payload.borderClass
        },
        setNotificationClose: (state, action) => {
            state.open = false;
                state.message = "";
                state.subText = "";
                state.alertType = "";
                state.borderClass = ""
        },
        authenticationError: (state, action) => {
            state.open = false;
                state.message = action.payload.message;
                state.subText = action.payload.subText;
                state.alertType = action.payload.alertType;
                state.borderClass = action.payload.borderClass
        }
    }
},
)
export const { setNotificationOpen, setNotificationClose, authenticationError } = notificationSlice.actions;
export default notificationSlice.reducer;

