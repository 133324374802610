import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import minusIcon from '../../Images/Icons/minusIcon.svg'
import plusIcon from '../../Images/Icons/PlusIcon.svg'

const Addons = (props: any) => {

    const [addonsDisable, setAddonsDisable] = useState(false)
    const [addons, setAddons]: any = useState([])
    // const [check, setCheck] = useState(false)

    useEffect(() => {
        setAddons(props.getAddons)
    }, [props.getAddons])


    const getAddonsQuantity = (id: any) => {
        let count = 0;
        if (props.addonObj && props.addonObj.length > 0) {
            count = props.addonObj?.find((item: any) => item?.item === id)?.quantity
        }
        return count ? count: 0;
    }
    return (
        <>
            {addons.map((item: any, idx: number) =>
                <div key={idx} className={`flex flex-row justify-between items-center p-3 gap-4 bg-white cursor-pointer ${styles.card} rounded-lg `}>
                    <div className={`flex flex-row items-center`}>
                        <div className={`flex items-center justify-center rounded-md border border-solid border-[#D9D9D9] p-2 bg-[#FAFAFA]`}>
                            <img crossOrigin="anonymous" src={`${process.env.REACT_APP_PUBLIC_apiurl}/uploads/?key=${item?.picture?.key}`} width={70} height={70} alt="img"/>
                        </div>
                        <div>
                            <p className={`text-left text-[#4F4F4F] text-base font-semibold font-[SansRegular] px-4`}>{item?.name}</p>
                            <p className={`text-left text-[#BDBDBD] text-sm font-normal font-[SansRegular] px-4`}>{item?.description}</p>
                        </div>
                    </div>
                    <div className={`flex flex-row justify-end`}>
                        <div className={`flex justify-between items-center px-4`}>
                            <div>
                                <p className={`text-lg text-[#4F4F4F] font-[SansRegular]`}>${item?.price}</p>
                            </div>
                            <div className={`flex items-center justify-around h-[30px] w-[100px] rounded-3xl ml-4 border border-solid border-[#e4e4e480] bg-[#ffffff]`}>
                                <div className={props?.addonObj?.some((add: any) => add?.item === item?._id && add?.quantity === 0) ? `pointer-events-none w-full h-full flex items-center justify-center` : `cursor-pointer w-full h-full flex items-center justify-center`} onClick={() => {
                                    props.handleDecreaseAddon(item)
                                }}>
                                    <img crossOrigin="anonymous" src={minusIcon} height={10} width={10} alt={`minusIcon`} />
                                </div>
                                <input
                                    type='number'
                                    id='id'
                                    max='999'
                                    disabled={addonsDisable}
                                    value={getAddonsQuantity(item?._id)}
                                    onChange={(e: any) => (e.target.value > 999 ? setAddonsDisable(true) : e.target.value < 0 ? setAddonsDisable(true) : props.onSetAddonsQuantity(e, item))}
                                    // onClick={() => props.selectId(item?._id)}
                                    className={`border-y border-solid border-[#e4e4e480] text-[#828282] text-lg font-[SansRegular] w-10 text-center outline-none focus:bg-[#bfd6ff] bg-[#ffffff] h-[30px]`}
                                />
                                <div className={`cursor-pointer w-full h-full flex items-center justify-center`} onClick={() => {
                                    props.handleIncreaseAddon(item)
                                }}>
                                    <img crossOrigin="anonymous" src={plusIcon} height={10} width={10} alt={`plusIcon`} />
                                </div>
                            </div>
                            <p className={`w-10 text-lg pl-2 text-[#4F4F4F] font-[SansRegular]`} >{props?.addonObj?.some((add: any) => add?.item === item?._id && add?.quantity !== 0) ? 'Pcs.' : 'Pc.'}</p>
                        </div>
                    </div>
                </div>)}
        </>
    )
}

export default Addons