import { Dialog, DialogContent, DialogTitle} from '@mui/material'
import React from 'react'
import ButtonFieldInput from '../uiComponents/buttonField/Button'
import crossicon from '../../Images/Icons/cross.svg'
import TextFieldInput from '../uiComponents/FormFields/TextFieldInput'
// import { SketchPicker } from 'react-color'
import { BlockPicker } from 'react-color'
import { NumericFormat } from 'react-number-format';

const CustomizePanelModal = (props: any) => {

    return (
        <div>
            <Dialog open={props.open} className='dialogBoxWidth'
            >
                <DialogTitle className={`p-0`}>
                    <div className={`flex justify-end h-2 mb-2`}>
                        <div className={`cursor-pointer`} onClick={props.onClose}>
                            <img
                                src={crossicon}
                                alt='crossicon'
                                height={15}
                                width={15}
                            />
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent className={`w-[40rem]`}>
                    <form onSubmit={props.panelForm.handleSubmit}>
                        <div className={`flex flex-col`}>
                            <div className={` flex flex-col gap-4`}>
                                <div className='flex gap-3 items-center'>
                                    <p className='w-[8rem]'>Panel Name:</p>
                                    <div className='w-[100%]'>
                                        <TextFieldInput
                                            style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
                                            textnewclass={`bg-gn-white-10 rounded-[8px] w-[100%]`}
                                            placeholder={`Enter Panel Name`}
                                            type={`text`}
                                            textinputname={`panelName`}
                                            id={`panelName`}
                                            onChange={props.panelForm.handleChange}
                                            onBlur={props.panelForm.handleBlur}
                                            value={props.panelForm.values.panelName}
                                            error={
                                                props.panelForm.touched.panelName &&
                                                Boolean(props.panelForm.errors.panelName)
                                            }
                                            errText={
                                                props.panelForm.touched.panelName &&
                                                props.panelForm.errors.panelName
                                            }
                                            clickEnter={props.panelForm.handleSubmit}
                                        />
                                    </div>
                                </div>
                                <div className='flex gap-2 items-center'>
                                    {/* height  */}
                                    <div className='flex gap-3 items-center'>
                                        <p className='w-[6.7rem]'>Height:</p>
                                        <div className='flex items-center gap-2'>
                                            <NumericFormat
                                                customInput={TextFieldInput}
                                                style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
                                                textnewclass={`bg-gn-white-10 rounded-[8px] addaddressInputFieldsGlobal`}
                                                placeholder={`Enter height in mm`}
                                                // type={`number`}
                                                textinputname={`height`}
                                                id={`height`}
                                                onChange={props.panelForm.handleChange}
                                                onBlur={props.panelForm.handleBlur}
                                                value={props.panelForm.values.height}
                                                error={
                                                    props.panelForm.touched.height &&
                                                    Boolean(props.panelForm.errors.height)
                                                }
                                                errText={
                                                    props.panelForm.touched.height &&
                                                    props.panelForm.errors.height
                                                }
                                                clickEnter={props.panelForm.handleSubmit}
                                            />
                                            {/* <label htmlFor={`heightFt`}>mm</label> */}
                                        </div>
                                    </div>
                                    {/* <p className='text-lg text-[#777]'>x</p> */}
                                    {/* width  */}
                                    <div className='flex items-center gap-3'>
                                        <p className=''>Width:</p>
                                        <div className='flex items-center gap-2'>
                                            <NumericFormat
                                                customInput={TextFieldInput}
                                                style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
                                                textnewclass={`bg-gn-white-10 rounded-[8px] addaddressInputFieldsGlobal`}
                                                placeholder={`Enter width in mm`}
                                                textinputname={`width`}
                                                id={`width`}
                                                onChange={props.panelForm.handleChange}
                                                onBlur={props.panelForm.handleBlur}
                                                value={props.panelForm.values.width}
                                                error={
                                                    props.panelForm.touched.width &&
                                                    Boolean(props.panelForm.errors.width)
                                                }
                                                errText={
                                                    props.panelForm.touched.width &&
                                                    props.panelForm.errors.width
                                                }
                                                clickEnter={props.panelForm.handleSubmit}
                                            />
                                            {/* <label htmlFor={`heightFt`}>mm</label> */}
                                        </div>
                                       
                                    </div>
                                </div>
                                <div className='flex gap-3 items-center'>
                                    <p className='w-[7.8rem]'>Thickness:</p>
                                    <div className=' w-[100%]'>
                                        <NumericFormat
                                            customInput={TextFieldInput}
                                            style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
                                            textnewclass={`bg-gn-white-10 rounded-[8px] addaddressInputFieldsGlobal`}
                                            placeholder={`Enter thickness in mm`}
                                            textinputname={`thickness`}
                                            id={`thickness`}
                                            onChange={props.panelForm.handleChange}
                                            onBlur={props.panelForm.handleBlur}
                                            value={props.panelForm.values.thickness}
                                            error={
                                                props.panelForm.touched.thickness &&
                                                Boolean(props.panelForm.errors.thickness)
                                            }
                                            errText={
                                                props.panelForm.touched.thickness &&
                                                props.panelForm.errors.thickness
                                            }
                                            clickEnter={props.panelForm.handleSubmit}
                                        />
                                        {/* <label htmlFor={`thickness`}>ft</label> */}
                                    </div>
                                    
                                </div>
                                <div className='flex gap-2 mt-4'>
                                    <p className={`mb-5 w-[6.5rem]`}>Select panel color</p>
                                    <BlockPicker
                                        color={  props.blockPickerColor}
                                        onChange={(color) => {
                                            props.handleColorPicker(color.hex);
                                        }}
                                        // onBlur={props.panelForm.handleBlur}

                                        // error={
                                        //     props.panelForm.touched.color &&
                                        //     Boolean(props.panelForm.errors.color)
                                        // }
                                        // errText={
                                        //     props.panelForm.touched.color &&
                                        //     props.panelForm.errors.color
                                        // }
                                    />
                                </div>
                            </div>
                            <div className='flex mt-4 justify-end'>
                                <ButtonFieldInput
                                    name="Submit"
                                    type="submit"
                                    handleButton={() => { }}
                                    buttonextracls={`!bg-[#2F80ED] !hover:bg-[#2F80ED] !text-white text-base  !py-2 !px-6 !rounded-3xl font-normal font-[SansRegular] justify-end`}
                                    loading={props.loader}
                                    disabled={props.loader === true && false}
                                />
                            </div>
                        </div>
                    </form>

                </DialogContent>
            </Dialog>
        </div>
    )
}

export default CustomizePanelModal