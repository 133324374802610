import * as React from 'react';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


import { replaceMp4LinksWithVideo, OEmbedToIframe } from '../../../utils/commonFunc'
import { handleHelpContent } from '../../../redux/helpModalSlice';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


export default function Videomodal({ open, onClose, videoUrl, dispatch}: any) {


  //state for modifiedContent set
  const [modifiedContent, setModifiedContent] = React.useState<string>("")


  //check
  React.useEffect(() => {
    if (videoUrl?.attributes?.type === "video") {
      setModifiedContent(replaceMp4LinksWithVideo(videoUrl && videoUrl?.attributes?.ckeContent?.ckeContent));
    }
    else if (videoUrl?.attributes?.type === "text") {
      setModifiedContent(videoUrl?.attributes?.ckeContent?.ckeContent ? OEmbedToIframe(videoUrl?.attributes?.ckeContent?.ckeContent) : "");
    }
  }, [videoUrl])






  // console.log(modifiedContent, "video html")
  const modifiedHtml: any = OEmbedToIframe(modifiedContent)


  return (
    <React.Fragment>


      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className='bootstrap'
        // onClick={()=> dispatch(handleHelpContent(null))}
        sx={{
          backdropFilter: "blur(5px)", width: "100%"
        }}

      >
        <DialogTitle sx={{}} id="">
          {/* <h2 className='text-center'> {videoTitle}</h2> */}
        </DialogTitle>

        <div className='w-full'>
          <DialogContent
            //style={{ width: '100%' }}
            className='flex items-center justify-center'
          >
            {/* {
              videoUrl  && <ReactPlayer url={videoUrl} controls={true} width="100%" />
            } */}

            {/* Modal component */}
            <div className={` helpvideomodal relative bg-transparent`}>
              <IconButton
                aria-label="close"
                onClick={()=> { onClose(); dispatch(handleHelpContent(null)) }}
                sx={{
                  position: 'absolute',
                  right: 2,
                  top: 3,
                  zIndex: 1, 
                  color: 'black',
                  height: "30px",
                  width: "30px"
                }}
              >
                <CloseIcon />
              </IconButton>
         <div className={`${videoUrl?.attributes?.type === 'video' ? "bg-transparent w-[70vw]" : " text-help-content max-w-[60vw] h-[80vh] bg-white p-10 overflow-y-scroll video_help_modal"}`}   dangerouslySetInnerHTML={{ __html: modifiedHtml?.replaceAll('"/uploads', `"${process.env.REACT_APP_PUBLIC_STRAPI_API_URL}/uploads`) }} ></div> 

            </div >




          </DialogContent>

        </div>



        {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions> */}


      </BootstrapDialog>
    </React.Fragment>
  );
}