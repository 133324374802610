import React, { useState, useEffect, useRef } from "react";

const loadScript = (url: string, callback: () => void) => {
    let script: any = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
        script.onreadystatechange = function () {
            if (script.readyState === "loaded" || script.readyState === "complete") {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
};



const PlacesAutoCompleteCustom = (props: any) => {
    const [query, setQuery] = useState("");
    const autoCompleteRef = useRef(null);
    let autoComplete: any;

    function handleScriptLoad(updateQuery: any, autoCompleteRef: any) {
        autoComplete = new window.google.maps.places.Autocomplete(
            autoCompleteRef.current,
            { fields: ['geometry', 'address_components', 'formatted_address', 'plus_code'], types: ['address'] }
        );
        autoComplete.setFields(["address_components", "formatted_address"]);
        autoComplete.addListener("place_changed", () => {
            handlePlaceSelect(updateQuery)
        }
        );
    }

    async function handlePlaceSelect(updateQuery: any) {
        // var geoCoder = new google.maps.Geocoder();
        const addressObject = autoComplete.getPlace();
        let addd: any = {
            city: "",
            state: "",
            country: "",
            postal_code: "",
        }
        addressObject.address_components?.map((ad: any) => {
            if (ad.types[0] === 'locality') {
                addd.city = ad.long_name
            } else if (ad.types[0] === 'administrative_area_level_1') {
                addd.state = ad.long_name
            } else if (ad.types[0] === "country") {
                addd.country = ad.long_name
            } else if (ad.types[0] = "postal_code" && ad.long_name.length > 4) {
                addd.postal_code = ad.long_name
            }

        })

        const queryAdd = addressObject?.formatted_address;
        updateQuery(queryAdd);

        addd.lat = addressObject.lat ? addressObject.lat : addressObject?.geometry?.location.lat()
        addd.lng = addressObject.lng ? addressObject.lng : addressObject?.geometry?.location.lng()
        addd.label = addressObject?.formatted_address
        props.forComponent === 'addAddress' ? props.changeAddress(addd) : props.shippingAddressVal(addd)
    }


    useEffect(() => {
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY}&libraries=places`,
            () => handleScriptLoad(setQuery, autoCompleteRef)
        );
    }, []);
    const handleValue = (e: any) => {
        setQuery(e)
        let address: any = {}
        address.label = e
        props.forComponent === 'addAddress' ? props.changeAddress(address) : props.shippingAddressVal(address)
    }

    return (
        <div className="search-location-input">
            <input
                id={props.id}
                ref={autoCompleteRef}
                onChange={(event: any) => handleValue(event.target.value)}
                placeholder="Address"
                value={props.shippingAddr?.label ? props.shippingAddr?.label : query}
                style={{ width: '100%', padding: '8px' }}
                className="autoField"
                disabled={props.disabled}
            />
        </div>
    );
}

export default PlacesAutoCompleteCustom;
