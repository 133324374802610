/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
// import Image from 'next/image'
// import React from 'react'
import noContent from '../../Images/myAccount/noContent.svg'
import { useNavigate, useSearchParams } from 'react-router-dom'


const AddQuote = (props: any) => {

    const navigate = useNavigate()
    const [searchParams] = useSearchParams();

    return (
        <div>
            <div className={`flex flex-col justify-center items-center pt-8 ${props.noContentRoot}`}>
                <img src={noContent} alt={'noContent'} />
                <p className={`font-medium text-[22px] text-center text-[#7E7E7E] w-4/5`}>{`You currently don’t have any net created. Create one to get started.`}</p>
                
                    {/* // <div className={`cursor-pointer w-fit bg-[#1473E6] my-4 px-6 rounded-full`} onClick={() => { setShowAddQuote(true); resetAll() }}> */}
                    <div className={`cursor-pointer w-fit bg-[#1473E6] my-4 px-6 rounded-full`} onClick={() => navigate(`/quote/editor?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}&index=${searchParams.get('index')?? 0}`)}>
                        <p className={`text-sm font-medium text-white text-center py-2.5`}>{'+ Create New'}</p>
                    </div>
            </div>
        </div>
    )
}

export default AddQuote