/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { doGetApiCall, doPutApiCall } from '../../../utils/ApiConfig'
import successIcon from '../../../Images/Icons/successIcon.svg'
// import Image from 'next/image'
import getLocalStorageData from '../../../utils/getLocalStorageData'
import { CircularProgress } from '@mui/material'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import { navigateToGn } from '../../../utils/navigateToGn'

const PaymentConfrim = (props: any) => {
  const navigate = useNavigate()
  const invoiceList = useSelector((state: any) => state?.invoiceReducer?.invoiceDetails)
  const myAccountList = useSelector((state: any) => state?.myAccountReducer?.myAccountDetails)
  const [disable, setDisable] = useState(false)
  const [searchParams] = useSearchParams();
  let token = getLocalStorageData("token");
  useEffect(() => {
      props.getInvoiceDetails()
  }, [])

  const orderIndex = Number(getLocalStorageData("orderIndex"))

  const grandTotal = ((myAccountList?.subTotal || invoiceList?.subTotal) + Number(searchParams?.get('tax')) + (myAccountList?.shipping || invoiceList?.shipping) - Number(searchParams?.get('offerValue'))).toFixed(2);

  useEffect(() => {
    if (  orderIndex ? myAccountList[orderIndex]?._id : invoiceList?._id) {
      setDisable(true)
      let data = {
        url: `${process.env.REACT_APP_PUBLIC_apiurl}/orders/${orderIndex ? myAccountList[orderIndex]?._id : invoiceList?._id}`,
        bodyData: {
          // paymentStatus: `${router.query.redirect_status === "succeeded" ? 'paid' : 'failed'}`,
          paymentIntentId: searchParams.get('payment_intent'),
          paymentStatus:  'paid',
          ...(searchParams?.get('offerValue') ? {
            salesId: searchParams?.get('salesId'),
            saleDiscountAmount: searchParams?.get('offerValue'),
            tax: searchParams?.get('tax'),
            taxRate: searchParams?.get('taxrate'),
          } : {})
        }
      }
      doPutApiCall(data)
        .then((res: any) => {
          if (!res.error) {
            getUserDetails()
          }
          else {
            setDisable(true)
            console.log(res.error);
          }
        })
        .catch((err: any) => {
          console.error(err);
        })
    }
  }, [invoiceList])

  const getUserDetails = async () => {
    setDisable(true)
    let userId = getLocalStorageData("userId") ? getLocalStorageData("userId") : getLocalStorageData("user")?._id;
    let data = {
      url: `${process.env.REACT_APP_PUBLIC_apiurl}/users/${userId}`
    }
    await doGetApiCall(data)
      .then((res: any) => {
        if (res.error) {
          navigate(`/login`)
          setDisable(false)
        }
        else {
          setDisable(false)
          if (getLocalStorageData("token")) {
            localStorage.setItem("user", JSON.stringify(res.result));
          }
          else {
            localStorage.clear()
          }
        }
      })
  }

  return (
    <div className={`flex flex-col justify-center items-center h-[500px]`}>
      <div>
        <img
          alt='Successful'
          src={successIcon}
          height={70}
          width={70}
          // objectFit="contain"
        />
      </div>
      <p className={`text-[#27AE60] font-[SansRegular] text-3xl`}>Payment Successful!</p>
      <p className={`text-[#4F4F4F] font-[SansRegular] text-xl mt-2`}>Thank you ! Your payment is successful</p>
      <p className={`text-[#4F4F4F] font-[SansRegular] text-xl mt-2`}>Quote Name : {orderIndex ? myAccountList[orderIndex]?.quoteId?.quoteName : invoiceList?.quoteId?.quoteName}</p>
      <p className={`text-[#4F4F4F] font-[SansRegular] text-xl mt-2`}>Quote Number : {orderIndex ? myAccountList[orderIndex]?.quoteId?.quoteNo : invoiceList?.quoteId?.quoteNo}</p>
      {/* <p className={`text-[#4F4F4F] font-[SansRegular] text-xl mt-2`}>Order ID : {router.query.payment_intent}</p> */}
      <p className={`text-[#4F4F4F] font-[SansRegular] text-xl mt-2`}>Amount Paid</p>
      <p className={`text-[#4F4F4F] font-[SansBold] text-xl mt-2`}>${searchParams?.get('offerValue') ? grandTotal : Number(orderIndex ? myAccountList[orderIndex]?.total : invoiceList.total).toFixed(2)}</p>
      {
        disable ?
          <div className={`cursor-not-allowed h-[40px] w-[120px] bg-[#acacac] flex justify-center items-center rounded-xl mt-2`}><CircularProgress size={20} color="inherit" /></div>
          :
          <NavLink to={navigateToGn('', searchParams.get('userId'), searchParams.get('quoteId'), searchParams.get('index'), token)}>
            <div className={`cursor-pointer h-[40px] w-[120px] bg-[#acacac] flex justify-center items-center rounded-xl mt-2`}><p className={`text-[#ffffff] font-[SansRegular] text-xl`}>Go to home</p></div></NavLink>
          // <div className={`cursor-pointer h-[40px] w-[120px] bg-[#acacac] flex justify-center items-center rounded-xl mt-2`} onClick={() => { router.push(`/`); localStorage.removeItem('orderIndex') }}><p className={`text-[#ffffff] font-[SansRegular] text-xl`}>Go to home</p></div>
      }
    </div>
  )
}

export default PaymentConfrim