import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';


const CancelMagicLinkRequest = (props: any) => {
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="responsive-dialog-title"
                className={`!max-w-full !min-w-max h-auto m-0 ${"dilogBox_main"}`}
                onClick={props.handleClickOpen}
            >
                <DialogTitle id="responsive-dialog-title" className={`flex justify-between items-center border-b border-slate-200 px-1.5 `}>
                    {props.modalText ? props.modalText : ""}
                    <CloseIcon onClick={props.handleClose} className={`cursor-pointer text-slate-500`} />
                </DialogTitle>
                <DialogContent className={`px-1.5 py-3 mt-4`}>
                    <DialogContentText >
                        {props.DilogContent ? props.DilogContent : ''}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={`px-1.5 py-3`}>
                    <Button autoFocus onClick={props.handleClose} className={`capitalize font-normal text-slate-600 px-3 py-1.5`}>
                        Cancel
                    </Button>
                    <Button onClick={() => props.handleClose('yes')} autoFocus className={`capitalize font-normal border-solid border px-3 py-1.5 border-wv-buttonblue !text-white !bg-[#1473E6] hover:bg-[#1473E6] rounded`}>

                        {props.ConfirmBtn}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default CancelMagicLinkRequest