/**
 * @uplnupurS
 * @description - this component is created for user login
 */

import React from "react";
// import Checkbox from "../../../common/uiComponents/checkbox/CheckBox";
import EmailFild from "../../../common/uiComponents/commonEmailField/EmailField";
import PasswordFieldInput from "../../../common/uiComponents/commonPasswordField/PasswordField";
import ButtonFieldInput from "../../../common/uiComponents/buttonField/Button";
import styles from "./Login.module.css";
import loginLeftSideimg from "../../../Images/authentication/Golfnet_login_compressed.jpg"
import text from "../../../common/en_US.json"
import { useSelector } from "react-redux";
import ForgotPassword from "../forgotPassword/ForgotPassword";
// import ForgotPassword from "../forgotPassword/ForgotPassword";
// import WVlogo from '../../../images/logo/Golf_net_logo.png'
import logoBlack from "../../../Images/logo/Solar_golfnet_logo_white_background.png";
import { useNavigate } from "react-router";
const Login = (props: any) => {
    // const isFetching = useSelector((state: any) => state.userReducer?.isFetching);
    const navigate = useNavigate()
    return (
        <div className={`flex flex-col h-full md:flex-row items-center sm:justify-start md:justify-start sm:gap-2 md:gap-12 sm:mb-2 md:mb-10`}>
            <div className="sm:mb-4 md:mb-0 invisible md:visible w-full h-full">
                <img src={loginLeftSideimg} alt="loginLeftSideimg" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </div>
            <div className="h-[90%] flex flex-col items-center sm:justify-center md:justify-center sm:w-[70%] md:w-[60%] ">
                <div className="pb-3 w-[65%] ">
                    {/* <p className="text-[32px] text-[#333333] font-bold text-center sm:text-2xl md:text-[32px]  font-[SansRegular] pb-2">
            {text.login}
          </p> */}
                    <div className={`m-auto cursor-pointer w-[4.5rem] h-14`}>
                        <img src={logoBlack} alt='solarGolfnetlogo' style={{ width: '100%', height: '100%' }} />
                    </div>
                    <p className="text-[#616060] text-center text-base font-normal sm:text-sm md:text-base  font-[SansRegular]">
                        {text.subLoginHeading}
                    </p>
                </div>

                <form onSubmit={props.loginForm?.handleSubmit} className="py-3 w-[65%]">
                    <div className="flex flex-col justify-between items-center h-56  ">
                        <EmailFild
                            extracls={`w-full`}
                            lableCls={styles.labelcs}
                            placeholder="Email"
                            textinputname="email"
                            variant="outlined"
                            id={`email`}
                            label="Email"
                            onChange={props.loginForm?.handleChange}
                            onBlur={props.loginForm?.handleBlur}
                            value={props.loginForm?.values?.email}
                            error={
                                props.loginForm?.touched?.email &&
                                Boolean(props.loginForm?.errors?.email)
                            }
                            helperText={
                                props.loginForm?.touched?.email && props.loginForm?.errors?.email
                            }
                            clickEnter={props.loginForm?.handleSubmit}
                        />
                        <PasswordFieldInput
                            extracls={`w-full`}
                            lableCls={styles.labelcs}
                            fullwidthState={true}
                            placeholder="Password"
                            passwordinputText="password"
                            label="Password"
                            id={`password`}
                            onChange={props.loginForm?.handleChange}
                            onBlur={props.loginForm?.handleBlur}
                            value={props.loginForm?.values?.password}
                            error={
                                props.loginForm?.touched?.password &&
                                Boolean(props.loginForm?.errors?.password)
                            }
                            helperText={
                                props.loginForm?.touched?.password &&
                                props.loginForm?.errors?.password
                            }
                            clickEnter={props.loginForm?.handleSubmit}
                        />
                        <div className={`mt-3 border-0 bg-wv-bg-black `}>
                            <p className={`text-red-500 text-center`}>{props.notificationLogin?.message}</p>
                        </div>
                        <div className="flex flex-col gap-2 justify-between items-start w-full  pb-2">
                            {/* <div className="flex items-center">
                <Checkbox />
                <p className="text-[#8C8C8C] text-sm font-normal ">{text.rememberMe}</p>
              </div> */}
                            <div onClick={() => navigate('/quote/adduser')}>
                                <p className="text-[#2b44ff] text-sm  cursor-pointer font-normal">
                                    Get Quote
                                </p>
                            </div>
                            <div onClick={props.handleOpenForgotModal}>
                                <p className="text-[#FF5C00] text-sm  cursor-pointer font-normal">
                                    {text.forgotPassword}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="pt-4 ">
                        <ButtonFieldInput
                            // loading={isFetching}
                            buttonextracls={`!bg-[#FF5C00] !text-white w-full  !normal-case h-12 !text-lg !rounded-3xl `}
                            name={text.login}
                        />
                    </div>
                    <div className={`mt-3 border-0 bg-wv-bg-black rounded-sm`}>
                        <p className={`text-red-700 text-center`}>{props.notification}</p>
                    </div>
                </form>
            </div >
            <ForgotPassword
                forgotPasswordFormik={props.forgotPasswordFormik}
                onClose={props.handleCloseForgotModal}
                open={props.openForgotModal}
                changeContent={props.changeContent}
            />
        </div >
    );
};

export default Login;
