import React, { useEffect, useState } from 'react'
import './index.css'
import { sideNavData } from './sideNavData'
import openClosePannelDrawer from '../../Images/sidenav/openClosePannelDrawer.svg'
import DeleteIcon from '../../Images/header/newIcons/DeleteIcon.svg';
import selectQuote from '../../Images/Icons/selectQuote.svg'
import { ForCanvasEditor } from '../../container/canvasEditor/Hooks';
import { Tooltip } from '@mui/material';
import minusIcon from '../../Images/Icons/minusIcon.svg'
import plusIcon from '../../Images/Icons/PlusIcon.svg'
import ConfirmationModal from '../uiComponents/ConfirmationModal';

const RightSideNav = (props: any) => {

    const { handleQuantityChange, netData, deleteNet, openDeleteNet, openDeleteNetModal,closeDeleteNet } = props

    const [openPreView, setOpenPreView] = useState(true)
    // const {netObj} = ForCanvasEditor()
    const detailsClass = `flex items-center justify-between w-full text-sm leading-[25px] text-[#454545] font-[RobotoRegular2nd] hover:bg-[#d8d8d850] px-5 cursor-pointer`
    const itemNumber = `flex items-center justify-between w-full text-sm leading-[25px] font-light text-[#5b5b5bda] text-[14px]`
    return (
        <div className={`w-full h-full relative`}>
            {/* {openPreView ? */}
            <div className={`rightsideNav-container overflow-x-hidden !h-[87vh] overflow-y-auto duration-500 ${openPreView ? `!w-52` : `!w-0`}`}>
                <div className='rightsideNav-item '>
                    {
                        netData?.map((item: any, idx: number) => {
                            return (
                                <div>
                                    <div className=' w-full !flex gap-3' key={idx} >
                                        <div className={`flex flex-col w-1/2 `}>
                                            <Tooltip title={item?.itemName}>
                                                <p className={`text-[#454545] font-[RobotoRegular2nd] px-5 !justify-start !text-base font-medium  hover:!bg-transparent w-32 truncate text-left cursor-pointer`} onClick={(e) => item?.json && props.renderNetObj(item)}>
                                                    {(item?.itemName?.length > 0 && item?.itemName?.toLowerCase() !== 'type') ? item?.itemName : (item?.name?.length > 0 && item?.name?.toLowerCase() !== 'type') ? item?.name : '*No name'}
                                                </p>
                                            </Tooltip>

                                            <p className={`${detailsClass}`} onClick={() => item?.json && props.renderNetObj(item)}>
                                                <span className={``}>Height:</span>
                                            </p>

                                            <p className={`${detailsClass}`} onClick={() => item?.json && props.renderNetObj(item)}>
                                                <span className={``}>Width:</span>
                                            </p>

                                            <p className={`${detailsClass}`} onClick={() => item?.json && props.renderNetObj(item)}>
                                                <span className={``}>Area:</span>
                                            </p>

                                        </div>

                                        <div className='flex flex-col justify-center w-1/2 '>

                                            <div className={`flex gap-2 cursor-pointer`}>
                                                {(item?.id === props.netObj?.id) ? <img src={selectQuote} alt='selected-icon' style={{ width: '18px' }} /> : null}
                                                <img src={DeleteIcon} alt='delete-icon' onClick={() => props.openDeleteNetModal(item.id)} style={{ width: '18px' }} />
                                            </div>

                                            <span className={`${itemNumber}`}>{item?.height.toFixed(2)} ft</span>

                                            <span className={`${itemNumber}`}>{item?.width.toFixed(2)} ft</span>

                                            <span className={`${itemNumber}`}>{item?.area.toFixed(2)} sq.ft</span>


                                        </div>

                                    </div>
                                    <div className={`flex items-center justify-around h-[30px] rounded-3xl mx-4 border border-solid border-[#e4e4e480] bg-[#ffffff]`}>
                                        <div className={`cursor-pointer w-[20px] h-full flex justify-center items-center`} onClick={() => handleQuantityChange(item?.id, 'minus')}>
                                            <img src={minusIcon} height={10} width={10} alt={`minusIcon`} />
                                        </div>
                                        <input type='number' id='id'
                                            max='40'
                                            value={item?.quantity ? item?.quantity?.toString() : "1"}
                                            onChange={(e) => handleQuantityChange(item?.id, 'type', Number(e.target.value))}
                                            className={`text-lg text-[#4F4F4F] font-normal font-roboto w-10 text-center outline-none border-y border-solid border-[#e4e4e480] focus:bg-[#bfd6ff] bg-[#ffffff] h-[30px]`}
                                        />
                                        <div className={`cursor-pointer w-[20px] h-full flex justify-center items-center`} onClick={() => handleQuantityChange(item?.id, 'plus')}>
                                            <img src={plusIcon} height={10} width={10} alt={`plusIcon`} />
                                        </div>
                                    </div>
                                </div>

                            )
                        })
                    }
                </div>
            </div>
            {/* : null} */}
            <div className={`absolute top-[50%] ${!openPreView ? `-left-5` : `-left-5`} rotate-180 z-10 h-12 w-6 flex items-center justify-end rounded-l-md !font-semibold font-[SansRegular] cursor-pointer`} onClick={() => setOpenPreView(!openPreView)}>
                <p className='flex gap-4 items-center'>
                    <span>
                        <img src={openClosePannelDrawer} alt="openClosePannelDrawer" />
                    </span>
                </p>
            </div>
            {openDeleteNet === true && (
                <ConfirmationModal
                    modalText="Delete Net"
                    open={openDeleteNet}
                    handleClose={closeDeleteNet}
                    handleChange={() => deleteNet()}
                    DilogContent={`Are you sure you want to delete this net?`}
                    ConfirmBtn={"Yes"}
                    loading={props.loader}
                />
            )}
        </div>
    )
}

export default RightSideNav