import React, { useEffect } from 'react'
import TextFieldInput from '../../common/uiComponents/FormFields/TextFieldInput'
import ButtonFieldInput from '../../common/uiComponents/buttonField/Button'
import text from "../../common/en_US.json"
import EmailFieldInput from '../../common/uiComponents/FormFields/EmailFieldInput'
import PhoneFieldInput from '../../common/uiComponents/FormFields/PhoneFieldInput'
import PasswordFieldInput from '../../common/uiComponents/commonPasswordField/PasswordField'
import r from "../../Images/addUser/Rarraow.svg"
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

const AddUser = (props: any) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams()

    useEffect(()=>{// This is used to navigate to editor page automatically when user lands on '/' path.
        if( searchParams.get('userId') && searchParams.get('quoteId') && searchParams.get('index') && searchParams.get('token')){
            navigate(`/quote/editor?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}&token=${searchParams.get('token')}&index=${searchParams.get('index') ?? 0}`);
        }else if(searchParams.get('userId') && searchParams.get('token')){
            navigate(`/quote/editor?userId=${searchParams.get('userId')}&token=${searchParams.get('token')}&index=${searchParams.get('index') ?? 0}`);
        }else if(searchParams.get('userId') && searchParams.get('quoteId')){
            navigate(`/quote/editor?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}&index=${searchParams.get('index') ?? 0}`);
        }else if(searchParams.get('quoteId') && searchParams.get('token')){
            navigate(`/quote/editor?quoteId=${searchParams.get('quoteId')}&token=${searchParams.get('token')}&index=${searchParams.get('index') ?? 0}`);
        }else{
            navigate(`/quote/editor`)
        }
    },[])
    return (
        <div className={`flex flex-col justify-center items-center pt-16 gap-10`}>
            <div className={`flex flex-col justify-center items-center`}>
                <p className={`text-2xl text-[#333333] tracking-wide font-medium font-[SansRegular]`}>{text.welcome}</p>
            </div>
            <form onSubmit={props?.userForm?.handleSubmit}>
                <div className={`flex flex-col gap-8`}>
                    <TextFieldInput
                        style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
                        textnewclass={`bg-gn-white-10 rounded-[8px] addUserInputFieldsGlobal`}
                        placeholder={`Full Name`}
                        type={`text`}
                        textinputname={`name`}
                        id={`name`}
                        extracls={`w-[16rem] sm:w-[30rem]`}
                        onChange={props?.userForm?.handleChange}
                        onBlur={props?.userForm?.handleBlur}
                        value={props?.userForm?.values.name}
                        error={
                            props?.userForm?.touched.name &&
                            Boolean(props?.userForm?.errors.name)
                        }
                        errText={
                            props?.userForm?.touched.name &&
                            props?.userForm?.errors.name
                        }
                        clickEnter={props?.userForm?.handleSubmit}
                    />
                    <EmailFieldInput
                        style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
                        textnewclass={`bg-gn-white-10 rounded-[8px] addUserInputFieldsGlobal`}
                        placeholder={`Email id`}
                        type={`email`}
                        textinputname={`email`}
                        id={`email`}
                        extracls={`w-[16rem] sm:w-[30rem]`}
                        onChange={props?.userForm?.handleChange}
                        onBlur={props?.userForm?.handleBlur}
                        value={props?.userForm?.values.email}
                        error={
                            props?.userForm?.touched.email &&
                            Boolean(props?.userForm?.errors.email)
                        }
                        errText={
                            props?.userForm?.touched.email &&
                            props?.userForm?.errors.email
                        }
                        clickEnter={props?.userForm?.handleSubmit}
                    />
                    <div style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }} className={`bg-gn-white-10 !border !border-solid !border-[#E0E0E0] !rounded-lg`}>
                        <PhoneFieldInput
                            
                            textnewclass={` `}
                            placeholder={`Phone Number`}
                            textinputname={`phoneno`}
                            onChange={props?.userForm?.handleChange}
                            value={props?.userForm?.values.phoneno}
                            error={
                                props?.userForm?.touched.phoneno &&
                                Boolean(props?.userForm?.errors.phoneno)
                            }
                            errorText={
                                props?.userForm?.touched.phoneno &&
                                props?.userForm?.errors.phoneno
                            }
                        />
                    </div>
                    <PasswordFieldInput
                        style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
                        textnewclass={`bg-gn-white-10 !border !border-solid !border-[#f5f5f5] !rounded-lg `}
                        fullwidthState={true}
                        placeholder="Password"
                        passwordinputText="password"
                        id="password"
                        onChange={props?.userForm?.handleChange}
                        onBlur={props?.userForm?.handleBlur}
                        value={props?.userForm?.values.password}
                        error={
                            props?.userForm?.touched.password &&
                            Boolean(props?.userForm?.errors.password)
                        }
                        helperText={
                            props?.userForm?.touched.password &&
                            props?.userForm?.errors.password
                        }
                        clickEnter={props?.userForm?.handleSubmit}
                    />
                    <PasswordFieldInput
                        style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
                        textnewclass={`bg-gn-white-10 !border !border-solid !border-[#f5f5f5] !rounded-lg `}
                        fullwidthState={true}
                        placeholder="Confirm password"
                        passwordinputText="confirmPassword"
                        id="confirmPassword"
                        onChange={props?.userForm?.handleChange}
                        onBlur={props?.userForm?.handleBlur}
                        value={props?.userForm?.values.confirmPassword}
                        error={
                            props?.userForm?.touched.confirmPassword &&
                            Boolean(props?.userForm?.errors.confirmPassword)
                        }
                        helperText={
                            props?.userForm?.touched.confirmPassword &&
                            props?.userForm?.errors.confirmPassword
                        }
                        clickEnter={props?.userForm?.handleSubmit}
                    />
                </div>
                <div className={`mt-2 border-0 bg-wv-bg-black pb-16 `}>
                    <p className={`text-red-500 text-center`}>{props.quoteNotification.message}</p> 
                </div>
                <div className={`flex justify-end w-[16rem] sm:w-[30rem] pt-5`}>
                    <ButtonFieldInput
                        name="Next"
                        type="submit"
                        endIcon={<img src={r} width={18} height={18} alt="arrow" />}
                        buttonextracls={`!text-white text-base !bg-[#1473E6] hover:bg-[#1473E6] !py-2 !px-6 !rounded-3xl font-normal font-[SansRegular] duration-500 cursor-pointer`}
                    />
                </div>
            </form>
        </div>
    )
}

export default AddUser