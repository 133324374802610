import React, { useEffect } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import getLocalStorageData from "../../../utils/getLocalStorageData";
import { doPutApiCall } from "../../../utils/ApiConfig";
import { CircularProgress } from "@mui/material";
import { useNavigation, useSearchParams } from "react-router-dom";

export default function CheckoutForm(props: any) {
  let hostname = window.location.hostname;
  const stripe = useStripe();
  const elements = useElements();
  // const router = useRouter();

  // const { quoteId } = useParams()
  const [searchParams] = useSearchParams();


  const [message, setMessage]: any = React.useState(null);
  const [isLoading, setIsLoading]: any = React.useState(false);

  React.useEffect(() => {
    if (!stripe) {
      return;
    }

    let clientSecret = getLocalStorageData("client_secret")

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then((paymentIntent: any) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("");
          break;
      }
    });
  }, [stripe]);

  const getConfirmUrl = () => {
    let url: any
    if(searchParams.get('offerValue')){
      url = `https://${hostname}/quote/paymentConfirm?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}&offerValue=${searchParams.get('offerValue')}&salesId=${searchParams.get('salesId')}&tax=${searchParams.get('tax')}&taxrate=${searchParams.get('taxrate')}&index=${searchParams.get('index') ?? 0}&paymentConfirm=true`
      return url;
    }else{
      url = `https://${hostname}/quote/paymentConfirm?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}&index=${searchParams.get('index') ?? 0}&paymentConfirm=true`
      return url;
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const error: any = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: `https://${hostname}/quote/paymentConfirm?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}&offerValue=${searchParams.get('offerValue')}&salesId=${searchParams.get('salesId')}&tax=${searchParams.get('tax')}&taxrate=${searchParams.get('taxrate')}&index=${searchParams.get('index') ?? 0}&paymentConfirm=true`,
        return_url: getConfirmUrl()
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage("An unexpected error occurred.");
      setMessage(error.message);
      paymentFailed()
      setIsLoading(false);
    } else {
      paymentFailed()
      setIsLoading(false);
      console.log(error, "show stripe error")
      setMessage(error?.error?.message);
    }
    localStorage.removeItem('quoteId')

    setIsLoading(false);
  };

  /**
   * @uplNiladri
   * @description payment failed 
   * 
   */

  const paymentFailed = () => {
    let data = {
      url: `${process.env.REACT_APP_PUBLIC_apiurl}/orders/${props.invoiceList?._id}`,
      bodyData: {
        orderStatus: `failed`,
        paymentStatus: `failed`
      }
    }
    doPutApiCall(data)
  }


  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div>
        <p className={`text-red-500 text-center mb-2`}>{message}</p>
      </div>
      <PaymentElement id="payment-element" />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className={`h-full bg-blue-400 w-24 rounded-3xl p-2 mt-4 flex items-center justify-center`}><CircularProgress size={23} color="inherit" /></div> : <p className={`bg-blue-400 w-24 rounded-3xl p-2 mt-4 text-white font-[SansRegular]`}>Pay Now</p>}
        </span>
      </button>
      {/* Show any error or success messages */}
      {/* {message && <div id="payment-message">{message}</div>} */}
    </form>
  );
}