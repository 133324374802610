import React from 'react';
import styles from './Index.module.css';
import PropTypes from 'prop-types';
// import { InputLabel } from '@mui/material';
// import { InputLabel, FormHelperText, Popover } from "@material-ui/core";
import InputMask from 'react-input-mask';
import MaskedInput from 'react-text-mask';
import { InputLabel } from '@mui/material';
// import { ErrorOutlined } from '@material-ui/icons';

function TextMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /\d/, /\d/, /\d/, ')', '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function PhoneFieldInput(props: any) {
  const [newState, setNewState] = React.useState({ mask: '(999)-999-9999' });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const onChange = (event: any) => {
    if (props.onChange) {
      var value = event.target.value;
      var newState = {
        mask: '(999)-999-9999',
        // value: value
      };

      if (/^3[47]/.test(value)) {
        newState.mask = '(999)-999-9999';
      }
      setNewState(newState);
      props?.onChange(event);
    }
  }

  const onKeyPress = (ev: any) => {
    if (props.onKeyPress) {
      if (ev.key === 'Enter') {
        ev.preventDefault();
        props.onKeyPress();
      }
    }
  }
  const clickEnter = (e: any) => {
    if (e.key === 'Enter') {
      // Do code here
      if (e.target.name === 'name') {
        e.preventDefault();
        props.clickEnter();
      }
    }
  };

  const onFocus = (e: any) => {
    if (props.onFocusOut) {
      if (e.currentTarget === e.target) {
        props.onFocusOut(props.textinputname)
      }
    }
  }

  return (
    <div className={((props.defaultValue || props.value) ? 'textfieldactive' : 'textfieddeactv ') + ' textfieldinput phonefieldInput'}>
      {/* InputLabel need to be updatu */}
      {props.inputLabel ? (
        <InputLabel
          shrink
          htmlFor="bootstrap-input"
          required={props.required}
          className={`textinputlabel ${props.extralabelcls} ${props.error && `${styles.inputmaskerror}`}`}
        >
          {props.inputLabel}
        </InputLabel>
      ) : null}
      <InputMask value={props.value} {...newState} onChange={onChange}
        style={props.style}
        placeholder={props.placeholder}
        name={props.textinputname}
        className={`textfieldclass ${props.textnewclass} ${props.errorText && `${styles.inputmaskerror}`}`}
        onKeyPress={(ev: any) => clickEnter(ev)}
        onFocus={(e: any) => onFocus(e)}
        disabled={props.disabled}
        id={props.id}
        data-cy={props.dataCy}

      //id={props.id}
      />
      {props.errorText ? (
        // <FormHelperText
        //   className="errormsg"
        //   aria-owns={open ? 'mouse-over-popover' : undefined}
        //   aria-haspopup="true"
        //   onMouseEnter={handlePopoverOpen}
        // >
        //   <p>{props.errorText}</p>
        // </FormHelperText>
        <p className={`${styles.phoneerror} pt-0.5 pl-1.5`}>{props.errorText}</p>
      ) : null}
    </div>
  );
}

PhoneFieldInput.propTypes = {
  inputLabel: PropTypes.string,
  extralabelcls: PropTypes.string,
  textnewclass: PropTypes.string,
  textinputname: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.any,
  onError: PropTypes.func,
  fullwidthState: PropTypes.bool,
  placeholder: PropTypes.string,
  InputProps: PropTypes.object,
  required: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onFocusOut: PropTypes.func,
  onKeyPress: PropTypes.func,
  dataCy: PropTypes.string,
  style: PropTypes.any
};

export default PhoneFieldInput;