import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAPI } from "../lib/api";

const fetchStrapiData = async (keywordItem: string, type?:string) => {
    let responseData: any = {};
    try {
      const token = process.env.REACT_APP_PUBLIC_STRAPI_API_TOKEN;
      const path = `/helps`;
      const urlParamsObject = {
        sort: { createdAt: "desc" },
        populate: {
          ckeContent: { populate: `*` },
        },
      };
      const options = { headers: { Authorization: `Bearer ${token}` } };
      responseData = await fetchAPI(path, urlParamsObject, options);


      //retrive or select particular array item when we click on item
      let dataArray = responseData.data;

      //find according to key
      dataArray = dataArray.find((data: any) => data.attributes.keyword === keywordItem);
      
     return {type: type, data: dataArray};

    } catch (error) {
      console.error("Error Occurs on Fetch Strapi api Data function :", error);
    }
  }


  export const fetchHelpContent:any = createAsyncThunk(
    'quoteInvoice/fetchHelpContent',
    async ({ keywordItem, type }:any) => {
      return fetchStrapiData(keywordItem, type);
    }
  );
  

export const initialState:any = {
  isModalOpen: false,
  helpContent: null,
  headerHelpContent: null,
  isLoading: false,
  error: null,
};

export const helpModalSlice = createSlice({
  name: 'quoteInvoice',
  initialState,
  reducers: {
    handleModal: (state, action) => {
      state.isModalOpen = action.payload;
    },
    handleHelpContent: (state, action) => {
        state.helpContent = action.payload;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHelpContent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchHelpContent.fulfilled, (state, action) => {
        state.isLoading = false;
        if(action.payload?.type !== 'HEADER_HELP_CONTENT'){
            state.helpContent = action.payload?.data;
            state.isModalOpen = true
        }else{
            state.headerHelpContent = action.payload.data;
        }
      
      })
      .addCase(fetchHelpContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { handleModal, handleHelpContent } = helpModalSlice.actions;
export default helpModalSlice.reducer;
