/**
 * @uplnupurS
 * Reusing This component from flo ButtonField
 */

import React from "react";
import { ButtonBase, CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import styles from "./Button.module.css";
// import Image from "next/image";

const ButtonFieldInput = (props: any) => {
    return (
        <Button
            variant={props.variant}
            className={`${props.buttonextracls}`}
            onClick={props.onClick}
            component={props.component}
            startIcon={props.startIcon}
            endIcon={props.endIcon}
            to={props.to}
            type={props.type ? props.type : "submit"}
            data-cy={props.dataCy}
            // disabled={
            // 	props.disabled
            // 		? props.disabled
            // 		: props.loading === true
            // 			? true
            // 			: false
            // }
            disabled={props.disabled}
        >
            {props.loading === true ? (
                <div className={`loaderwithtxt ${props?.loadingText ? 'flex items-center justify-center gap-2' : ""}`}>
                    <CircularProgress size={18} className='' color='inherit' />
                    {props?.loadingText && <span className={props?.buttonTextClass ? props?.buttonTextClass : 'text-[11px] !font-light'}>{props?.loadingText}</span>}
                </div>
            ) : (
                <div className={`flex items-center justify-center ${props.buttonstyle}`}>
                    {props.img && (
                        <div className='flex'>
                            <img
                                src={props.img}
                                height={30}
                                width={30}
                                alt='Button image'
                            />
                        </div>
                    )}
                    <p className={`${props.buttonnamecls}`}>{props.name}</p>
                </div>
            )}
        </Button>
    );
};

export default ButtonFieldInput;
